import React from 'react';
import { Clock, User, FileText, MessageSquare } from 'lucide-react';

interface ActivityListProps {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  filters: {
    type: string;
    client: string;
  };
}

export default function ActivityList({ dateRange, filters }: ActivityListProps) {
  const activities = [
    {
      id: '1',
      type: 'client_meeting',
      client: {
        name: 'Sarah Johnson',
        avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
      },
      description: 'Financial planning session completed',
      duration: 60,
      notes: 'Discussed retirement goals and investment strategy',
      timestamp: '2024-03-19T14:30:00Z',
    },
    {
      id: '2',
      type: 'document_review',
      client: {
        name: 'Emily Parker',
        avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80',
      },
      description: 'Reviewed investment portfolio',
      duration: 30,
      notes: 'Updated asset allocation recommendations',
      timestamp: '2024-03-19T12:15:00Z',
    },
    // Add more activities
  ];

  const getIcon = (type: string) => {
    switch (type) {
      case 'client_meeting':
        return <User className="h-5 w-5 text-blue-500" />;
      case 'document_review':
        return <FileText className="h-5 w-5 text-green-500" />;
      case 'message':
        return <MessageSquare className="h-5 w-5 text-purple-500" />;
      default:
        return <Clock className="h-5 w-5 text-gray-400" />;
    }
  };

  return (
    <div className="space-y-4">
      {activities.map((activity) => (
        <div
          key={activity.id}
          className="flex items-start space-x-4 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
        >
          <div className="flex-shrink-0">
            {getIcon(activity.type)}
          </div>
          <div className="flex-1 min-w-0">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={activity.client.avatar}
                  alt={activity.client.name}
                  className="h-6 w-6 rounded-full mr-2"
                />
                <p className="text-sm font-medium text-gray-900 dark:text-white">
                  {activity.client.name}
                </p>
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {new Date(activity.timestamp).toLocaleString()}
              </p>
            </div>
            <p className="mt-1 text-sm text-gray-900 dark:text-white">
              {activity.description}
            </p>
            {activity.notes && (
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                {activity.notes}
              </p>
            )}
            <div className="mt-2 flex items-center text-xs text-gray-500 dark:text-gray-400">
              <Clock className="h-4 w-4 mr-1" />
              {activity.duration} minutes
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}