import { useEffect } from 'react';
import { useNotificationStore } from '../store/notificationStore';
import { useClientStore } from '../store/clientStore';
import { addDays, isPast, isWithinInterval } from 'date-fns';

export const useAdminNotifications = () => {
  const { addNotification } = useNotificationStore();
  const { journeys } = useClientStore();

  useEffect(() => {
    // Check for expiring access
    const checkExpiringAccess = () => {
      journeys.forEach(journey => {
        // Check if access expires within 7 days
        const expirationDate = addDays(new Date(journey.startDate), 90);
        
        if (isWithinInterval(expirationDate, {
          start: new Date(),
          end: addDays(new Date(), 7)
        })) {
          addNotification({
            title: 'Access Expiring Soon',
            message: `Your access to ${journey.user.name}'s account expires in ${
              Math.ceil((expirationDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))
            } days`,
            type: 'expiration',
            priority: 'high',
            metadata: {
              clientId: journey.userId,
              expirationDate: expirationDate.toISOString(),
              actionUrl: `/admin/clients/${journey.userId}`,
            },
          });
        }
      });
    };

    // Check for overdue tasks
    const checkOverdueTasks = () => {
      journeys.forEach(journey => {
        journey.tasks.forEach(task => {
          if (!task.completed && isPast(new Date(task.dueDate))) {
            addNotification({
              title: 'Overdue Task',
              message: `Task "${task.title}" for ${journey.user.name} is overdue`,
              type: 'task',
              priority: 'high',
              metadata: {
                clientId: journey.userId,
                taskId: task.id,
                actionUrl: `/admin/clients/${journey.userId}`,
              },
            });
          }
        });
      });
    };

    // Initial check
    checkExpiringAccess();
    checkOverdueTasks();

    // Set up periodic checks
    const interval = setInterval(() => {
      checkExpiringAccess();
      checkOverdueTasks();
    }, 1000 * 60 * 60); // Check every hour

    return () => clearInterval(interval);
  }, [journeys, addNotification]);
};