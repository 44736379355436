import React from 'react';
import { Clock, Users, CheckCircle, TrendingUp } from 'lucide-react';

interface ActivityMetricsProps {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
}

export default function ActivityMetrics({ dateRange }: ActivityMetricsProps) {
  const metrics = [
    {
      name: 'Total Time',
      value: '32.5 hours',
      change: '+12.5%',
      icon: Clock,
      color: 'text-blue-500',
    },
    {
      name: 'Clients Served',
      value: '18',
      change: '+5.2%',
      icon: Users,
      color: 'text-green-500',
    },
    {
      name: 'Tasks Completed',
      value: '45',
      change: '+8.1%',
      icon: CheckCircle,
      color: 'text-purple-500',
    },
    {
      name: 'Efficiency Rate',
      value: '94%',
      change: '+2.3%',
      icon: TrendingUp,
      color: 'text-primary-400',
    },
  ];

  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
      {metrics.map((metric) => (
        <div
          key={metric.name}
          className="bg-white dark:bg-gray-800 overflow-hidden rounded-lg shadow"
        >
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <metric.icon className={`h-6 w-6 ${metric.color}`} />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                    {metric.name}
                  </dt>
                  <dd>
                    <div className="text-lg font-medium text-gray-900 dark:text-white">
                      {metric.value}
                    </div>
                    <div className="inline-flex items-baseline text-sm font-semibold text-green-600">
                      {metric.change}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}