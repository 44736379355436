import React, { useCallback, useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuthStore } from "./store/authStore";
import { useThemeStore } from "./store/themeStore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./config/firebase";

// Layout Components
import AppLayout from "./components/layout/AppLayout";

import AdminLayout from "./components/layout/AdminLayout";
import HomeTab from "./pages/admin/HomeTab";
import ClientsTab from "./pages/admin/ClientsTab";
import ClientProfile from "./components/admin/clients/ClientProfile";
import ActivitiesTab from "./pages/admin/ActivitiesTab";
import AdminSettings from "./pages/admin/AdminSettings";

// Auth Components
import LoginForm from "./components/auth/LoginForm";
import RegisterForm from "./components/auth/RegisterForm";

// Pages
import Dashboard from "./pages/Dashboard";
import ReportsPage from "./pages/ReportsPage";
import BudgetPage from "./pages/BudgetPage";
import ToolsPage from "./pages/ToolsPage";
import CoursesPage from "./pages/CoursesPage";
import CourseDetailsPage from "./pages/CourseDetailsPage";
import MentorshipPage from "./pages/MentorshipPage";
import CommunityPage from "./pages/CommunityPage";
import ProfilePage from "./pages/ProfilePage";
import SettingsPage from "./pages/SettingsPage";
import TransactionPage from "./pages/TransactionPage";

// Account Pages
import AccountsOverview from "./pages/accounts/AccountsOverview";
import BankAccountsPage from "./pages/accounts/BankAccountsPage";
import CreditCardsPage from "./pages/accounts/CreditCardsPage";
import LoansPage from "./pages/accounts/LoansPage";
import RetirementAccountsPage from "./pages/accounts/RetirementAccountsPage";
import OtherAccountsPage from "./pages/accounts/OtherAccountsPage";

// Investment Pages
import InvestmentsLayout from "./pages/investments/InvestmentsLayout";
import InvestmentsOverview from "./pages/investments/InvestmentsOverview";
import StocksPage from "./pages/investments/StocksPage";
import BondsPage from "./pages/investments/BondsPage";
import CommoditiesPage from "./pages/investments/CommoditiesPage";
import RealEstatePage from "./pages/investments/RealEstatePage";
import CryptoPage from "./pages/investments/CryptoPage";
import OtherInvestmentsPage from "./pages/investments/OtherInvestmentsPage";

// Admin Pages
import UsersPage from "./pages/admin/UsersPage";
import ClientPortalPage from "./pages/admin/ClientPortalPage";
import CourseManagementPage from "./pages/admin/CourseManagementPage";
import MentorshipAdminPage from "./pages/admin/MentorshipAdminPage";
import CommunityModerationPage from "./pages/admin/CommunityModerationPage";
import UserViewPage from "./pages/admin/UserViewPage";
import Context from "./Context";
import { callMyServer } from "./utils/utils";
import { api } from "./utils/api";
import { doc, updateDoc } from "firebase/firestore";

function App() {
  const { isDarkMode } = useThemeStore();
  const { isAuthenticated, user } = useAuthStore();
  const isAdmin = user?.role === "admin";

  // const { linkSuccess, isPaymentInitiation, itemId, dispatch } =
  //   useContext(Context);
  const { dispatch } = useContext(Context);

  enum CraCheckReportProduct {
    BaseReport = "cra_base_report",
    IncomeInsights = "cra_income_insights",
    PartnerInsights = "cra_partner_insights",
    NetworkInsights = "cra_network_insights",
  }

  const getInfo = useCallback(async () => {
    if (!dispatch) {
      console.error("Dispatch is not defined");
      return;
    }

    const response = await api.post("/info", {
      access_token: user.access_token,
      item_id: user.item_id,
    });

    // if (!response.accesss_token || !response.item_id) {
    //   console.log(response.access_token);
    //   console.log(response.item_id);
    //   return { paymentInitiation: false };
    // }

    // const data = await response.json();
    // console.log(data);

    const paymentInitiation: boolean =
      response.products.includes("payment_initiation");
    const craEnumValues = Object.values(CraCheckReportProduct);
    const isUserTokenFlow: boolean = response.products.some(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );
    const isCraProductsExclusively: boolean = response.products.every(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );

    dispatch({
      type: "SET_STATE",
      state: {
        products: response.products,
        isPaymentInitiation: paymentInitiation,
        isCraProductsExclusively: isCraProductsExclusively,
        isUserTokenFlow: isUserTokenFlow,
      },
    });
    return { paymentInitiation, isUserTokenFlow };
  }, [dispatch]);

  const generateUserToken = useCallback(async () => {
    const response = await api.post("/create_user_token", {});
    if (!response.ok) {
      console.log(response);
      dispatch({ type: "SET_STATE", state: { userToken: null } });

      return;
    }
    const data = await response.json();
    if (data) {
      if (data.error != null) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
            linkTokenError: data.error,
          },
        });
        return;
      }
      dispatch({ type: "SET_STATE", state: { userToken: data.user_token } });
      console.log(data);

      return data.user_token;
    }
  }, [dispatch]);

  const generateToken = useCallback(
    async (isPaymentInitiation) => {
      // Link tokens for 'payment_initiation' use a different creation flow in your backend.

      const path =
        // isPaymentInitiation
        // ? "/api/create_link_token_for_payment"
        "/create_link_token";
      const response = await api.post(path, {
        user_id: user.id,
        user_name: user.name,
      });
      // if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { linkToken: null } });

      // return;
      // }
      // const data = await response.text();

      // if (data) {
      if (!response.link_token) {
        console.log(response);

        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
          },
        });
        return;
      }

      dispatch({
        type: "SET_STATE",
        state: { linkToken: response.link_token },
      });
    },

    [dispatch]
  );

  useEffect(() => {
    const init = async () => {
      const { paymentInitiation, isUserTokenFlow } = await getInfo(); // used to determine which path to take when generating token
      // do not generate a new token for OAuth redirect; instead

      if (isUserTokenFlow) {
        await generateUserToken();
      }
      generateToken(paymentInitiation);
    };
    init();
  }, [dispatch, generateToken, generateUserToken, getInfo]);

  if (!isAuthenticated) {
    return (
      <div className={isDarkMode ? "dark" : ""}>
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    );
  }

  return (
    <div className={isDarkMode ? "dark" : ""}>
      <Routes>
        {isAdmin ? (
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<HomeTab />} />
            <Route path="clients" element={<ClientsTab />} />
            <Route path="clients/:clientId" element={<ClientProfile />} />
            <Route path="activities" element={<ActivitiesTab />} />
            <Route path="settings" element={<AdminSettings />} />
          </Route>
        ) : (
          <Route path="/*" element={<AppLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="reports" element={<ReportsPage />} />
            <Route path="accounts">
              <Route index element={<AccountsOverview />} />
              <Route path="bank" element={<BankAccountsPage />} />
              <Route path="credit" element={<CreditCardsPage />} />
              <Route path="loans" element={<LoansPage />} />
              <Route path="retirement" element={<RetirementAccountsPage />} />
              <Route path="other" element={<OtherAccountsPage />} />
            </Route>
            <Route path="budget" element={<BudgetPage />} />
            <Route path="investments" element={<InvestmentsLayout />}>
              <Route index element={<InvestmentsOverview />} />
              <Route path="stocks" element={<StocksPage />} />
              <Route path="bonds" element={<BondsPage />} />
              <Route path="commodities" element={<CommoditiesPage />} />
              <Route path="real-estate" element={<RealEstatePage />} />
              <Route path="crypto" element={<CryptoPage />} />
              <Route path="other" element={<OtherInvestmentsPage />} />
            </Route>
            <Route path="tools" element={<ToolsPage />} />
            <Route path="courses" element={<CoursesPage />} />
            <Route path="courses/:courseId" element={<CourseDetailsPage />} />
            <Route path="mentorship" element={<MentorshipPage />} />
            <Route path="community" element={<CommunityPage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="transactions" element={<TransactionPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        )}
      </Routes>
    </div>
  );
}

export default App;
