import React from 'react';
import { Users, CheckSquare, Bell, TrendingUp } from 'lucide-react';
import ClientOverview from '../../components/admin/dashboard/ClientOverview';
import TasksList from '../../components/admin/dashboard/TasksList';
import NotificationsList from '../../components/admin/dashboard/NotificationsList';
import ActivityChart from '../../components/admin/dashboard/ActivityChart';

export default function HomeTab() {
  const stats = [
    { name: 'Active Clients', value: '24', change: '+2.5%', icon: Users },
    { name: 'Pending Tasks', value: '12', change: '-4.1%', icon: CheckSquare },
    { name: 'Access Requests', value: '5', change: '+12%', icon: Bell },
    { name: 'Client Growth', value: '18%', change: '+2.3%', icon: TrendingUp },
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((stat) => (
          <div
            key={stat.name}
            className="bg-white dark:bg-gray-800 overflow-hidden rounded-lg shadow"
          >
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <stat.icon className="h-6 w-6 text-primary-400" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                      {stat.name}
                    </dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900 dark:text-white">
                        {stat.value}
                      </div>
                      <div className="inline-flex items-baseline text-sm font-semibold text-green-600">
                        {stat.change}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <ClientOverview />
        <TasksList />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <NotificationsList />
        <ActivityChart />
      </div>
    </div>
  );
}