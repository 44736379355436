import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  updateEmail,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  User,
} from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, db, storage } from "../config/firebase";
import {
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import QRCode from "qrcode";
import { PhoneAuthProvider } from "firebase/auth";
import { useEffect } from "react";
import { callMyServer } from "../utils/utils.ts";
import { useNavigate } from "react-router-dom";
import { api } from "../utils/api.ts";

export const useAuthStore = create(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      error: null,
      twoFactorSetup: null,

      // Login Function
      login: async (email: string, password: string) => {
        try {
          const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
          );

          const user = userCredential.user;

          const userDocRef = doc(db, "users", user.uid);

          // Fetch the document
          const userDoc = await getDoc(userDocRef);

          const userInfo = userDoc.data();

          const userData = {
            id: user.uid,
            name: user.displayName || "No name",
            email: user.email || "",
            role: userInfo.role,
            avatar: user.photoURL || "default-avatar-url",
            createdAt: user.metadata.creationTime || new Date().toISOString(),
            phoneNumber: userInfo.phoneNumber || "",
            location: userInfo.location || "",
            shareInfo: userInfo.shareInfo || "",
            bio: userInfo.bio || "",
            access_token: userInfo.access_token || "",
            item_id: userInfo.item_id || "",
          };

          set({
            user: userData,
            isAuthenticated: true,
            error: null,
          });

          return userData.role;
        } catch (error: any) {
          console.log(error);

          set({
            error: error.message || "Login failed",
          });
          throw error;
        }
      },

      // Logout Function
      logout: async () => {
        try {
          await signOut(auth);
          set({
            user: null,
            isAuthenticated: false,
            error: null,
            twoFactorSetup: null,
          });
        } catch (error: any) {
          set({
            error: error.message || "Logout failed",
          });
          throw error;
        }
      },

      // Register Function
      register: async (email: string, password: string, name: string) => {
        try {
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );

          const user = userCredential.user;

          // Update the user's display name
          await updateProfile(user, { displayName: name });

          const userData = {
            id: user.uid,
            name: name,
            email: user.email || "",
            role: "user",
            avatar: user.photoURL || "default-avatar-url",
            createdAt: user.metadata.creationTime || new Date().toISOString(),
            phoneNumber: "",
            location: user.location || "",
            bio: user.bio || "",
            TwoFactorSetup: false,
            shareInfo: false,
            access_token: "",
            item_id: "",
          };

          await setDoc(doc(db, "users", userData.id), {
            ...userData,
          });

          set({
            user: userData,
            isAuthenticated: true,
            error: null,
          });
        } catch (error: any) {
          set({
            error: error.message || "Registration failed",
          });
          throw error;
        }
      },

      clearError: () => set({ error: null }),

      updateUserProfile: async (
        data: {
          email?: string;
          location?: string;
          photoURL?: string;
          name?: string;
          phone?: string;
          bio?: string;
        },
        password: string
      ) => {
        try {
          const user = auth.currentUser;

          if (!user) {
            throw new Error("No authenticated user found.");
          }

          // Update email
          if (data.email && data.email !== user.email) {
            const emailUpdateSuccess = await updateEmail(
              user,
              data.email
            ).catch(async (error) => {
              if (error.message.includes("recent-login")) {
                if (password.length >= 6) {
                  // Re-authenticate the user
                  await signInWithEmailAndPassword(auth, user.email!, password);
                  await updateEmail(user, data.email);
                  return true;
                } else {
                  return false;
                }
              }
              throw error; // Re-throw other errors
            });

            if (emailUpdateSuccess === false) {
              return false; // Exit the function if re-authentication fails
            }
          }

          // Update name and photoURL
          if (data.name || data.photoURL) {
            await updateProfile(user, {
              displayName: data.name,
              photoURL: data.photoURL,
            });
          }

          // Update Firestore document
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const updatedData = {
              phoneNumber: data.phone,
              location: data.location,
              bio: data.bio,
              email: data.email,
              name: data.name,
              ...(data.photoURL && { photoURL: data.photoURL }),
            };

            await updateDoc(userDocRef, updatedData);
          } else {
            throw new Error("User document does not exist in Firestore.");
          }

          // Update Zustand state
          set((state: any) => ({
            user: {
              ...state.user,
              name: data.name !== undefined ? data.name : state.user?.name,
              avatar:
                data.photoURL !== undefined
                  ? data.photoURL
                  : state.user?.avatar,
              email: data.email !== undefined ? data.email : state.user?.email,
              phoneNumber:
                data.phone !== undefined ? data.phone : state.user?.phoneNumber,
              location:
                data.location !== undefined
                  ? data.location
                  : state.user?.location,
              bio: data.bio !== undefined ? data.bio : state.user?.bio,
            },
          }));

          console.log("Profile updated successfully!");
          return true; // Indicate success
        } catch (error: any) {
          set({
            error: error.message || "Failed to update profile",
          });
          console.error("Failed to update profile:", error);
          throw error; // Re-throw the error to the caller
        }
      },

      updateAvatar: async (file: File) => {
        try {
          const user = auth.currentUser;

          if (!user) {
            throw new Error("No authenticated user found.");
          }

          const avatarRef = ref(storage, `avatars/${user.uid}/${file.name}`);

          const snapshot = await uploadBytes(avatarRef, file);

          const photoURL = await getDownloadURL(snapshot.ref);

          await updateProfile(user, { photoURL });

          set((state) => ({
            user: {
              ...state.user,
              avatar: photoURL,
            },
          }));

          return photoURL; // Return the updated URL if needed elsewhere
        } catch (error: any) {
          set({
            error: error.message || "Failed to update avatar",
          });
          throw error;
        }
      },

      addAccessTokenAndItemId: async (response: any, user: string) => {
        try {
          const userDocRef = doc(db, "users", user.id);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            await updateDoc(userDocRef, {
              access_token: response.access_token,
              item_id: response.item_id,
            });

            set((state: any) => ({
              user: {
                ...state.user,
                access_token: response.access_token,
                item_id: response.item_id,
              },
            }));
          } else {
            throw new Error("User document does not exist in Firestore.");
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      },

      setupTwoFactor: async (user: string) => {
        try {
          let container = document.getElementById("recaptcha-container");

          // Initialize reCAPTCHA
          const recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container", // Make sure this ID exists in your DOM
            { size: "invisible" }, // Invisible reCAPTCHA
            auth
          );

          // Render the reCAPTCHA
          await recaptchaVerifier.render();

          // Send SMS for 2FA
          const confirmationResult = await signInWithPhoneNumber(
            auth,
            user.phoneNumber,
            recaptchaVerifier
          );

          console.log("Verification ID:", confirmationResult.verificationId);
          return confirmationResult.verificationId; // Save this for verifying the code
        } catch (error) {
          console.error("Failed to send SMS:", error.message);
          throw error;
        }
      },

      genShareableUrl: async function (user: User) {
        const response = await callMyServer(
          "/api/generate_shareable_url", // Endpoint
          true, // isPost set to true
          {
            email_address: user.email, // Post data
            user_id: user.id,
          }
        );

        console.log("Server response:", response.status);
        return response.shareable_url;
      },

      plaidAuth: async function (access_token: User) {
        const response = await api.get("/auth", access_token);
        console.log(response);
        return response.shareable_url;
      },

      plaidTransactions: async function (access_token: User) {
        const response = await api.get("/transactions/sync", access_token);
        console.log(response);
        return response;
      },

      plaidInvestments: async function (access_token: User) {
        const response = await api.get("/investments/auth/get", access_token);
        console.log(response);
        return response;
      },

      plaidIdentity: async function (access_token: User) {
        const response = await api.get("/identity", access_token);
        console.log(response);
        return response;
      },

      plaidBalance: async function (access_token: User) {
        const response = await api.get("/balance", access_token);
        console.log(response);
        return response;
      },

      // plaidHoldings: async function (access_token: User) {
      //   const response = await api.get("/holdings", access_token);
      //   console.log(response);
      //   return response;
      // },

      plaidLiabilities: async function (access_token: User) {
        const response = api.get("/liabilities", access_token);
        console.log(response);
        return response;
      },

      plaidItem: async function (access_token: User) {
        const response = await api.get("/item", access_token);
        console.log(response);
        return response;
      },

      plaidAccounts: async function (access_token: User) {
        const response = await api.get("/accounts", access_token);
        console.log(response);
        return response;
      },

      verifyTwoFactor: async () => {
        throw new Error("2FA verification functionality not yet implemented.");
      },

      disableTwoFactor: async () => {
        throw new Error("2FA disable functionality not yet implemented.");
      },
    }),
    {
      name: "auth-storage",
      partialize: (state) => ({
        user: state.user,
        isAuthenticated: state.isAuthenticated,
      }),
    }
  )
);
