import { withRetry } from "./retry";

const API_URL =
  // process.env.NODE_ENV === "production"
  "https://her-finances-backend-next.vercel.app/api";
// : "http://localhost:5173/api";

export const api = {
  get: async (endpoint: string, token: any) => {
    return withRetry(async () => {
      try {
        const url = `${API_URL}${
          endpoint.startsWith("/") ? endpoint : "/" + endpoint
        }`;
        console.log(`Making GET request to: ${url}`);
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("API Error:", {
            url,
            status: response.status,
            statusText: response.statusText,
            error: errorText,
          });
          throw new Error(
            `API request failed: ${response.status} ${response.statusText}`
          );
        }

        const result = await response.json();
        return result;
      } catch (error: any) {
        console.error("Request failed:", {
          endpoint,
          error: error.message,
          stack: error.stack,
        });

        if (
          error instanceof TypeError &&
          error.message.includes("Failed to fetch")
        ) {
          throw new Error(
            "Unable to connect to the API. Please check your connection."
          );
        }
        throw error;
      }
    });
  },

  post: async (endpoint: string, data: any) => {
    return withRetry(async () => {
      try {
        const url = `${API_URL}${
          endpoint.startsWith("/") ? endpoint : "/" + endpoint
        }`;

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
          body: JSON.stringify(data),
          credentials: "include",
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("API Error:", {
            url,
            status: response.status,
            statusText: response.statusText,
            error: errorText,
            requestData: data,
          });
          throw new Error(
            `API request failed: ${response.status} ${response.statusText}`
          );
        }

        const responseData = await response.json();

        return responseData;
      } catch (error: any) {
        console.error("Request failed:", {
          endpoint,
          error: error.message,
          stack: error.stack,
          requestData: data,
        });

        if (
          error instanceof TypeError &&
          error.message.includes("Failed to fetch")
        ) {
          throw new Error(
            "Unable to connect to the API. Please check your connection."
          );
        }
        throw error;
      }
    });
  },

  upload: async (endpoint: string, file: File) => {
    return withRetry(async () => {
      try {
        const url = `${API_URL}${
          endpoint.startsWith("/") ? endpoint : "/" + endpoint
        }`;
        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
          body: formData,
          credentials: "include",
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("API Error:", {
            url,
            status: response.status,
            statusText: response.statusText,
            error: errorText,
            fileName: file.name,
          });
          throw new Error(
            `Upload failed: ${response.status} ${response.statusText}`
          );
        }

        const data = await response.json();
        return data;
      } catch (error: any) {
        console.error("Upload failed:", {
          endpoint,
          error: error.message,
          stack: error.stack,
          fileName: file.name,
        });

        if (
          error instanceof TypeError &&
          error.message.includes("Failed to fetch")
        ) {
          throw new Error(
            "Unable to connect to the API. Please check your connection."
          );
        }
        throw error;
      }
    });
  },
};
