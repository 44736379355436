import React, { useState } from "react";
import { useBudgetStore } from "../store/budgetStore";
import BudgetOverview from "../components/budget/BudgetOverview";
import BudgetCategoryList from "../components/budget/BudgetCategoryList";
import SavingsGoalsList from "../components/budget/SavingsGoalsList";
import SpendingInsights from "../components/budget/SpendingInsights";
import AddBudgetItemModal from "../components/budget/AddBudgetItemModal";
import AddSavingsGoalModal from "../components/budget/AddSavingsGoalModal";

function BudgetPage() {
  const [isAddBudgetModalOpen, setIsAddBudgetModalOpen] = useState(false);
  const [isAddGoalModalOpen, setIsAddGoalModalOpen] = useState(false);
  const { budgetItems, savingsGoals } = useBudgetStore();

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
        Budget & Savings
      </h1>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 space-y-6">
          <BudgetOverview budgetItems={budgetItems} />
          <BudgetCategoryList budgetItems={budgetItems} />
          <SpendingInsights budgetItems={budgetItems} />
        </div>
        <div>
          <SavingsGoalsList goals={savingsGoals} />
        </div>
      </div>

      <AddBudgetItemModal
        isOpen={isAddBudgetModalOpen}
        onClose={() => setIsAddBudgetModalOpen(false)}
      />
      <AddSavingsGoalModal
        isOpen={isAddGoalModalOpen}
        onClose={() => setIsAddGoalModalOpen(false)}
      />
    </div>
  );
}

export default BudgetPage;
