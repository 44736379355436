export const callMyServer = async function (
  endpoint,
  isPost = false,
  postData = null
) {
  const optionsObj = isPost ? { method: "POST" } : {};
  if (isPost && postData !== null) {
    optionsObj.headers = { "Content-type": "application/json" };
    optionsObj.body = JSON.stringify(postData);
  }

  console.log(endpoint, optionsObj);

  try {
    const response = await fetch(endpoint, optionsObj);
    if (response.status === 500) {
      await handleServerError(response);
      return;
    }
    const data = await response.json();
    console.log(
      `Result from calling ${endpoint}: ${JSON.stringify(data, null, 2)}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

// export const callMyServer = async function (
//   endpoint,
//   isPost = false,
//   postData = null
// ) {
//   const optionsObj = isPost ? { method: "POST" } : {};
//   if (isPost && postData !== null) {
//     optionsObj.headers = { "Content-type": "application/json" };
//     optionsObj.body = JSON.stringify(postData);
//   }

//   console.log(endpoint, optionsObj);

//   const response = await fetch(endpoint, optionsObj);
//   console.log("Server response status:", response.status);
//   console.log("Server response headers:", response.headers);

//   // Log the raw response text for debugging
//   const rawText = await response.text();
//   console.log("Raw response text:", rawText);

//   // Re-throw server errors for clarity
//   if (!response.ok) {
//     console.error(`Error from ${endpoint}:`, rawText);
//     throw new Error(rawText || "Server error");
//   }

//   // Parse JSON after confirming the response is valid
//   return JSON.parse(rawText);
// };

const handleServerError = async function (responseObject) {
  const error = await responseObject.json();
  console.error("I received an error ", error);
  if (error.hasOwnProperty("error_message")) {
    console.log(`Error: ${error.error_message} -- See console for more`);
  }
};
