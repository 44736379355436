import { create } from "zustand";
import { Transaction } from "../types/transaction";
import { generateMockTransactions } from "../utils/mockTransactionGenerator";
import { startOfMonth, endOfMonth, isWithinInterval } from "date-fns";
import { transactions } from "../../server/transactions.js";

interface TransactionState {
  transactions: Transaction[];
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  grouping: "day" | "week" | "month";
  isLoading: boolean;
  error: string | null;
  addTransaction: (transaction: Omit<Transaction, "id">) => void;
  updateTransaction: (id: string, data: Partial<Transaction>) => void;
  deleteTransaction: (id: string) => void;
  addReceipt: (transactionId: string, file: File) => Promise<void>;
  updateNotes: (transactionId: string, notes: string) => void;
  addTags: (transactionId: string, tags: string[]) => void;
  getRecentTransactions: (limit?: number) => Transaction[];
  getTransactionsByCategory: () => Record<string, number>;
  getTransactionsByDate: (startDate: Date, endDate: Date) => Transaction[];
  setDateRange: (startDate: Date, endDate: Date) => void;
  setGrouping: (grouping: "day" | "week" | "month") => void;
}

// Initialize with 30 days of mock transactions
const mockTransactions = generateMockTransactions(30);

export const useTransactionStore = create<TransactionState>((set, get) => ({
  transactions: transactions,
  dateRange: {
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
  },
  grouping: "week",
  isLoading: false,
  error: null,

  addTransaction: (transaction) => {
    const newTransaction = {
      ...transaction,
      id: Math.random().toString(36).substr(2, 9),
    };
    set((state) => ({
      transactions: [newTransaction, ...state.transactions],
    }));
  },

  updateTransaction: (id, data) => {
    set((state) => ({
      transactions: state.transactions.map((t) =>
        t.id === id ? { ...t, ...data } : t
      ),
    }));
  },

  deleteTransaction: (id) => {
    set((state) => ({
      transactions: state.transactions.filter((t) => t.id !== id),
    }));
  },

  addReceipt: async (transactionId, file) => {
    try {
      const receiptUrl = URL.createObjectURL(file);
      set((state) => ({
        transactions: state.transactions.map((t) =>
          t.id === transactionId
            ? {
                ...t,
                receipt: {
                  url: receiptUrl,
                  uploadDate: new Date().toISOString(),
                },
              }
            : t
        ),
      }));
    } catch (error: any) {
      set({ error: error.message });
    }
  },

  updateNotes: (transactionId, notes) => {
    set((state) => ({
      transactions: state.transactions.map((t) =>
        t.id === transactionId ? { ...t, notes } : t
      ),
    }));
  },

  addTags: (transactionId, tags) => {
    set((state) => ({
      transactions: state.transactions.map((t) =>
        t.id === transactionId
          ? { ...t, tags: [...(t.tags || []), ...tags] }
          : t
      ),
    }));
  },

  getRecentTransactions: (limit = 5) => {
    const { transactions } = get();
    return transactions
      .sort(
        (a, b) =>
          new Date(b.date + "T" + b.time).getTime() -
          new Date(a.date + "T" + a.time).getTime()
      )
      .slice(0, limit);
  },

  getTransactionsByCategory: () => {
    const { transactions, dateRange } = get();
    const expenseTransactions = transactions.filter((t) => {
      const transactionDate = new Date(t.date);
      return (
        isWithinInterval(transactionDate, dateRange) && t.type === "expense"
      );
    });

    return expenseTransactions.reduce((acc, t) => {
      acc[t.category] = (acc[t.category] || 0) + t.amount;
      return acc;
    }, {} as Record<string, number>);
  },

  getTransactionsByDate: (startDate: Date, endDate: Date) => {
    const { transactions } = get();
    return transactions.filter((t) => {
      const transactionDate = new Date(t.date);
      return isWithinInterval(transactionDate, {
        start: startDate,
        end: endDate,
      });
    });
  },

  setDateRange: (startDate: Date, endDate: Date) => {
    set({ dateRange: { startDate, endDate } });
  },

  setGrouping: (grouping) => {
    set({ grouping });
  },
}));
