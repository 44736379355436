import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function ActivityChart() {
  const data = [
    { date: '2024-03-01', clients: 18, tasks: 45 },
    { date: '2024-03-08', clients: 20, tasks: 52 },
    { date: '2024-03-15', clients: 24, tasks: 58 },
    // Add more data points
  ];

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-6">Activity Overview</h2>
      
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date" 
              tickFormatter={(date) => new Date(date).toLocaleDateString()}
            />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="clients"
              stackId="1"
              stroke="#B100FF"
              fill="#B100FF"
              fillOpacity={0.2}
              name="Active Clients"
            />
            <Area
              type="monotone"
              dataKey="tasks"
              stackId="2"
              stroke="#10B981"
              fill="#10B981"
              fillOpacity={0.2}
              name="Completed Tasks"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}