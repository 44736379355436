import React, { useState } from 'react';
import { Plus, Edit2, Trash2, CheckCircle, Target } from 'lucide-react';

interface Goal {
  id: string;
  title: string;
  type: string;
  targetAmount: number;
  currentAmount: number;
  deadline: string;
  status: 'active' | 'completed' | 'cancelled';
  progress: number;
}

interface ClientGoalsProps {
  clientId: string;
}

export default function ClientGoals({ clientId }: ClientGoalsProps) {
  const [goals, setGoals] = useState<Goal[]>([
    {
      id: '1',
      title: 'Emergency Fund',
      type: 'savings',
      targetAmount: 25000,
      currentAmount: 15000,
      deadline: '2024-12-31',
      status: 'active',
      progress: 60,
    },
    {
      id: '2',
      title: 'Retirement Savings',
      type: 'retirement',
      targetAmount: 1000000,
      currentAmount: 450000,
      deadline: '2040-12-31',
      status: 'active',
      progress: 45,
    },
  ]);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900 dark:text-white">
          Financial Goals
        </h2>
        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500">
          <Plus className="h-5 w-5 mr-2" />
          Add Goal
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {goals.map((goal) => (
          <div
            key={goal.id}
            className="bg-white dark:bg-gray-800 shadow rounded-lg p-6"
          >
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <Target className="h-5 w-5 text-primary-400 mr-3" />
                <div>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    {goal.title}
                  </h3>
                  <p className="text-sm text-gray-500 capitalize">{goal.type}</p>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <button className="p-2 text-gray-400 hover:text-gray-500">
                  <Edit2 className="h-5 w-5" />
                </button>
                <button className="p-2 text-gray-400 hover:text-red-500">
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            </div>

            <div className="mt-4">
              <div className="flex justify-between mb-2">
                <span className="text-sm text-gray-500">Progress</span>
                <span className="text-sm font-medium text-gray-900 dark:text-white">
                  ${goal.currentAmount.toLocaleString()} of ${goal.targetAmount.toLocaleString()}
                </span>
              </div>
              <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
                <div
                  className="bg-primary-400 h-2.5 rounded-full"
                  style={{ width: `${goal.progress}%` }}
                />
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Target Date: {new Date(goal.deadline).toLocaleDateString()}
              </p>
            </div>

            <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    On track to meet goal
                  </span>
                </div>
                <button className="text-sm text-primary-400 hover:text-primary-500">
                  Update Progress
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}