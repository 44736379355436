import React from 'react';
import { CheckCircle, Clock, AlertCircle } from 'lucide-react';

interface Milestone {
  id: string;
  title: string;
  description: string;
  dueDate: string;
  status: 'completed' | 'upcoming' | 'overdue';
  completedDate?: string;
}

interface ClientMilestonesProps {
  clientId: string;
}

export default function ClientMilestones({ clientId }: ClientMilestonesProps) {
  const milestones: Milestone[] = [
    {
      id: '1',
      title: 'Initial Financial Assessment',
      description: 'Complete comprehensive review of current financial situation',
      dueDate: '2024-03-01',
      status: 'completed',
      completedDate: '2024-02-28',
    },
    {
      id: '2',
      title: 'Investment Strategy Review',
      description: 'Review and adjust investment portfolio allocation',
      dueDate: '2024-03-25',
      status: 'upcoming',
    },
    {
      id: '3',
      title: 'Tax Planning Session',
      description: 'Develop tax optimization strategies',
      dueDate: '2024-03-15',
      status: 'overdue',
    },
  ];

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <CheckCircle className="h-5 w-5 text-green-500" />;
      case 'upcoming':
        return <Clock className="h-5 w-5 text-yellow-500" />;
      case 'overdue':
        return <AlertCircle className="h-5 w-5 text-red-500" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'text-green-500 bg-green-50 dark:bg-green-900/20';
      case 'upcoming':
        return 'text-yellow-500 bg-yellow-50 dark:bg-yellow-900/20';
      case 'overdue':
        return 'text-red-500 bg-red-50 dark:bg-red-900/20';
      default:
        return '';
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
        Program Milestones
      </h2>

      <div className="space-y-4">
        {milestones.map((milestone) => (
          <div
            key={milestone.id}
            className="flex items-start space-x-4 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
          >
            <div className="flex-shrink-0">
              {getStatusIcon(milestone.status)}
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                  {milestone.title}
                </h3>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(milestone.status)}`}>
                  {milestone.status}
                </span>
              </div>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                {milestone.description}
              </p>
              <div className="mt-2 flex items-center text-xs text-gray-500">
                <span>Due: {new Date(milestone.dueDate).toLocaleDateString()}</span>
                {milestone.completedDate && (
                  <>
                    <span className="mx-2">•</span>
                    <span>
                      Completed: {new Date(milestone.completedDate).toLocaleDateString()}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}