import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

export default function ClientOverview() {
  const navigate = useNavigate();
  
  const clients = [
    {
      id: '1',
      name: 'Sarah Johnson',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
      program: 'Financial Planning',
      progress: 75,
      nextMeeting: '2024-03-20T10:00:00Z',
    },
    {
      id: '2',
      name: 'Emily Parker',
      avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80',
      program: 'Investment Strategy',
      progress: 45,
      nextMeeting: '2024-03-21T15:30:00Z',
    },
    // Add more mock clients as needed
  ];

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-medium text-gray-900 dark:text-white">Active Clients</h2>
          <button
            onClick={() => navigate('/admin/clients')}
            className="text-sm text-primary-400 hover:text-primary-500 flex items-center"
          >
            View all
            <ArrowRight className="ml-1 h-4 w-4" />
          </button>
        </div>

        <div className="space-y-4">
          {clients.map((client) => (
            <div
              key={client.id}
              className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
            >
              <div className="flex items-center">
                <img
                  src={client.avatar}
                  alt={client.name}
                  className="h-10 w-10 rounded-full"
                />
                <div className="ml-4">
                  <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                    {client.name}
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {client.program}
                  </p>
                </div>
              </div>
              <div className="text-right">
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  {client.progress}% Complete
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Next: {new Date(client.nextMeeting).toLocaleDateString()}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}