import React, { useState } from "react";
import { useTransactionStore } from "../store/transactionStore";
import { Transaction, TransactionFilters } from "../types/transaction";
import TransactionsList from "../components/transactions/TransactionsList";
import TransactionSummary from "../components/transactions/TransactionSummary";
import TransactionAnalytics from "../components/transactions/TransactionAnalytics";
import TransactionInsights from "../components/transactions/TransactionInsights";
import TransactionDetailsModal from "../components/transactions/TransactionDetailsModal";
import DateRangeSelector from "../components/transactions/DateRangeSelector";
import { subDays } from "date-fns";

function TransactionsPage() {
  const [selectedTransaction, setSelectedTransaction] =
    useState<Transaction | null>(null);
  const [startDate, setStartDate] = useState(() => subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(() => new Date());
  const [grouping, setGrouping] = useState<"day" | "week" | "month">("week");
  const { getTransactionsByDate } = useTransactionStore();

  const filteredTransactions = getTransactionsByDate(startDate, endDate);

  const handleDateRangeChange = (newStartDate: Date, newEndDate: Date) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Transactions
        </h1>
      </div>

      <DateRangeSelector
        startDate={startDate}
        endDate={endDate}
        onDateRangeChange={handleDateRangeChange}
        onGroupingChange={setGrouping}
      />

      <TransactionSummary
        transactions={filteredTransactions}
        dateRange={{ startDate, endDate }}
      />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <div className="space-y-4">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">
              Transaction History
            </h2>

            <TransactionsList
              transactions={filteredTransactions}
              onTransactionClick={setSelectedTransaction}
              dateRange={{ startDate, endDate }}
            />
          </div>
        </div>

        <div className="space-y-6">
          <TransactionAnalytics
            grouping={grouping}
            dateRange={{ startDate, endDate }}
          />
          <TransactionInsights
            transactions={filteredTransactions}
            dateRange={{ startDate, endDate }}
          />
        </div>
      </div>

      {selectedTransaction && (
        <TransactionDetailsModal
          transaction={selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
        />
      )}
    </div>
  );
}

export default TransactionsPage;
