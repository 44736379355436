import React, { useState } from 'react';
import { Plus, Edit2, Trash2, User } from 'lucide-react';

interface Note {
  id: string;
  content: string;
  author: {
    name: string;
    avatar: string;
  };
  createdAt: string;
  category: string;
}

interface ClientNotesProps {
  clientId: string;
}

export default function ClientNotes({ clientId }: ClientNotesProps) {
  const [notes, setNotes] = useState<Note[]>([
    {
      id: '1',
      content: 'Client expressed interest in sustainable investing options. Follow up with ESG portfolio recommendations.',
      author: {
        name: 'Admin User',
        avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
      },
      createdAt: '2024-03-19T14:30:00Z',
      category: 'investment',
    },
    {
      id: '2',
      content: 'Completed quarterly portfolio review. Client is satisfied with current allocation but wants to increase monthly contributions.',
      author: {
        name: 'Admin User',
        avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
      },
      createdAt: '2024-03-15T10:00:00Z',
      category: 'review',
    },
  ]);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900 dark:text-white">
          Client Notes
        </h2>
        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500">
          <Plus className="h-5 w-5 mr-2" />
          Add Note
        </button>
      </div>

      <div className="space-y-4">
        {notes.map((note) => (
          <div
            key={note.id}
            className="bg-white dark:bg-gray-800 shadow rounded-lg p-6"
          >
            <div className="flex items-start justify-between">
              <div className="flex items-start space-x-3">
                <img
                  src={note.author.avatar}
                  alt={note.author.name}
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <div className="flex items-center space-x-2">
                    <span className="text-sm font-medium text-gray-900 dark:text-white">
                      {note.author.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {new Date(note.createdAt).toLocaleString()}
                    </span>
                  </div>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-100 text-primary-800 mt-1">
                    {note.category}
                  </span>
                  <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
                    {note.content}
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <button className="p-2 text-gray-400 hover:text-gray-500">
                  <Edit2 className="h-5 w-5" />
                </button>
                <button className="p-2 text-gray-400 hover:text-red-500">
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}