import { format, subDays, addDays } from 'date-fns';
import { Transaction, TransactionType } from '../types/transaction';

// Realistic merchants with logos and locations
const merchants = [
  {
    name: 'Whole Foods Market',
    category: 'Groceries',
    logo: 'https://logo.clearbit.com/wholefoods.com',
    location: {
      address: '399 4th Street',
      city: 'San Francisco',
      state: 'CA',
      country: 'USA',
    },
  },
  {
    name: 'Amazon',
    category: 'Shopping',
    logo: 'https://logo.clearbit.com/amazon.com',
    location: {
      address: 'Online Purchase',
      city: 'Seattle',
      state: 'WA',
      country: 'USA',
    },
  },
  {
    name: 'Starbucks',
    category: 'Food & Dining',
    logo: 'https://logo.clearbit.com/starbucks.com',
    location: {
      address: '201 Powell Street',
      city: 'San Francisco',
      state: 'CA',
      country: 'USA',
    },
  },
  {
    name: 'Netflix',
    category: 'Entertainment',
    logo: 'https://logo.clearbit.com/netflix.com',
    location: {
      address: 'Online Service',
      city: 'Los Gatos',
      state: 'CA',
      country: 'USA',
    },
  },
  {
    name: 'Target',
    category: 'Shopping',
    logo: 'https://logo.clearbit.com/target.com',
    location: {
      address: '789 Mission Street',
      city: 'San Francisco',
      state: 'CA',
      country: 'USA',
    },
  },
  {
    name: 'Chase Bank',
    category: 'Salary',
    logo: 'https://logo.clearbit.com/chase.com',
    location: {
      address: 'Direct Deposit',
      city: 'New York',
      state: 'NY',
      country: 'USA',
    },
  },
];

// Generate a random transaction
const generateRandomTransaction = (date: Date): Transaction => {
  const merchant = merchants[Math.floor(Math.random() * merchants.length)];
  const isSalary = merchant.category === 'Salary';
  const type: TransactionType = isSalary ? 'income' : 'expense';
  const baseAmount = isSalary ? 5000 : Math.floor(Math.random() * 200) + 10;
  const amount = Math.round(baseAmount * 100) / 100;

  // Generate a random time between 9 AM and 10 PM
  const hours = Math.floor(Math.random() * 13) + 9;
  const minutes = Math.floor(Math.random() * 60);
  const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  return {
    id: Math.random().toString(36).substr(2, 9),
    date: format(date, 'yyyy-MM-dd'),
    time,
    description: isSalary ? 'Monthly Salary' : `Purchase at ${merchant.name}`,
    amount,
    currency: 'USD',
    type,
    status: 'completed',
    category: merchant.category,
    paymentMethod: isSalary ? 'direct_deposit' : 'credit_card',
    paymentDetails: {
      accountName: isSalary ? 'Chase Checking' : 'Chase Sapphire',
      cardLast4: isSalary ? undefined : '4567',
      bankName: 'Chase',
    },
    merchant,
    recurring: isSalary || merchant.name === 'Netflix',
  };
};

// Generate mock transactions for a given number of days
export const generateMockTransactions = (days: number): Transaction[] => {
  const transactions: Transaction[] = [];
  const today = new Date();

  // Generate 2-4 transactions per day
  for (let i = 0; i < days; i++) {
    const date = subDays(today, i);
    const numTransactions = Math.floor(Math.random() * 3) + 2;

    for (let j = 0; j < numTransactions; j++) {
      transactions.push(generateRandomTransaction(date));
    }

    // Add salary on the 1st and 15th of each month
    const dayOfMonth = date.getDate();
    if (dayOfMonth === 1 || dayOfMonth === 15) {
      transactions.push({
        ...generateRandomTransaction(date),
        merchant: merchants.find(m => m.category === 'Salary')!,
        type: 'income',
        amount: 5000,
        description: 'Monthly Salary',
        paymentMethod: 'direct_deposit',
        recurring: true,
      });
    }
  }

  return transactions.sort((a, b) => {
    const dateA = new Date(`${a.date}T${a.time}`);
    const dateB = new Date(`${b.date}T${b.time}`);
    return dateB.getTime() - dateA.getTime();
  });
};