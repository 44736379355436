import React, { useState } from 'react';
import { 
  FileText, 
  Download, 
  Trash2, 
  Upload,
  Eye,
  Lock,
  Calendar
} from 'lucide-react';

interface Document {
  id: string;
  name: string;
  type: string;
  size: string;
  uploadedAt: string;
  lastViewed?: string;
  status: 'active' | 'archived';
  secure: boolean;
}

interface ClientDocumentsProps {
  clientId: string;
}

export default function ClientDocuments({ clientId }: ClientDocumentsProps) {
  const [documents, setDocuments] = useState<Document[]>([
    {
      id: '1',
      name: 'Financial Plan 2024.pdf',
      type: 'application/pdf',
      size: '2.4 MB',
      uploadedAt: '2024-03-15T10:00:00Z',
      lastViewed: '2024-03-19T14:30:00Z',
      status: 'active',
      secure: true,
    },
    {
      id: '2',
      name: 'Investment Portfolio.xlsx',
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      size: '1.8 MB',
      uploadedAt: '2024-03-10T15:30:00Z',
      lastViewed: '2024-03-18T09:15:00Z',
      status: 'active',
      secure: true,
    },
  ]);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900 dark:text-white">
          Documents
        </h2>
        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500">
          <Upload className="h-5 w-5 mr-2" />
          Upload Document
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden">
        <div className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <div className="bg-gray-50 dark:bg-gray-700">
            <div className="grid grid-cols-6 gap-3 px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
              <div className="col-span-2">Name</div>
              <div>Size</div>
              <div>Uploaded</div>
              <div>Last Viewed</div>
              <div>Actions</div>
            </div>
          </div>
          <div className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {documents.map((doc) => (
              <div key={doc.id} className="grid grid-cols-6 gap-3 px-6 py-4 items-center">
                <div className="col-span-2">
                  <div className="flex items-center">
                    <FileText className="h-5 w-5 text-gray-400 mr-3" />
                    <div>
                      <div className="flex items-center">
                        <span className="text-sm font-medium text-gray-900 dark:text-white">
                          {doc.name}
                        </span>
                        {doc.secure && (
                          <Lock className="h-4 w-4 text-gray-400 ml-2" />
                        )}
                      </div>
                      <span className="text-xs text-gray-500">{doc.type}</span>
                    </div>
                  </div>
                </div>
                <div className="text-sm text-gray-500">{doc.size}</div>
                <div className="text-sm text-gray-500">
                  {new Date(doc.uploadedAt).toLocaleDateString()}
                </div>
                <div className="text-sm text-gray-500">
                  {doc.lastViewed
                    ? new Date(doc.lastViewed).toLocaleDateString()
                    : 'Never'}
                </div>
                <div className="flex items-center space-x-3">
                  <button className="p-2 text-gray-400 hover:text-gray-500">
                    <Eye className="h-5 w-5" />
                  </button>
                  <button className="p-2 text-gray-400 hover:text-gray-500">
                    <Download className="h-5 w-5" />
                  </button>
                  <button className="p-2 text-gray-400 hover:text-red-500">
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}