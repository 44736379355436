import React from 'react';
import { format } from 'date-fns';
import { X, Calendar } from 'lucide-react';
import { ClientJourney } from '../../../types/client';

interface ClientDetailsModalProps {
  journey: ClientJourney;
  onClose: () => void;
  onPhaseChange: (newPhase: string) => void;
}

function ClientDetailsModal({ journey, onClose, onPhaseChange }: ClientDetailsModalProps) {
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" />
        
        <div 
          className="relative w-full max-w-2xl bg-white dark:bg-gray-800 rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
            <div>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {journey.user.name}
              </h2>
              <p className="text-sm text-gray-500">Session {journey.phase}</p>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="p-6 space-y-6">
            {/* Progress Section */}
            <div>
              <h3 className="text-lg font-medium mb-4">Progress Overview</h3>
              <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                <div className="flex justify-between mb-2">
                  <span className="text-sm text-gray-500">Overall Progress</span>
                  <span className="text-sm font-medium">{journey.progress}%</span>
                </div>
                <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
                  <div
                    className="bg-primary-400 rounded-full h-2"
                    style={{ width: `${journey.progress}%` }}
                  />
                </div>
              </div>
            </div>

            {/* Next Milestone */}
            <div>
              <h3 className="text-lg font-medium mb-4">Next Milestone</h3>
              <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Calendar className="h-5 w-5 text-primary-400 mr-3" />
                    <div>
                      <p className="font-medium">{journey.nextMilestone.title}</p>
                      <p className="text-sm text-gray-500">
                        Due: {format(new Date(journey.nextMilestone.dueDate), 'MMM d, yyyy')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Tasks Section */}
            <div>
              <h3 className="text-lg font-medium mb-4">Tasks</h3>
              <div className="space-y-3">
                {journey.tasks.map(task => (
                  <div 
                    key={task.id}
                    className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg"
                  >
                    <div>
                      <p className="font-medium">{task.title}</p>
                      <p className="text-sm text-gray-500">
                        Due: {format(new Date(task.dueDate), 'MMM d, yyyy')}
                      </p>
                    </div>
                    <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                      task.completed
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {task.completed ? 'Completed' : 'Pending'}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailsModal;