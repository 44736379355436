import React from 'react';
import { Filter } from 'lucide-react';

interface ActivityFiltersProps {
  filters: {
    type: string;
    client: string;
  };
  onChange: (filters: { type: string; client: string }) => void;
}

export default function ActivityFilters({ filters, onChange }: ActivityFiltersProps) {
  return (
    <div className="flex items-center space-x-4">
      <select
        value={filters.type}
        onChange={(e) => onChange({ ...filters, type: e.target.value })}
        className="block w-40 pl-3 pr-10 py-2 text-base border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-sm rounded-md dark:bg-gray-700"
      >
        <option value="all">All Types</option>
        <option value="client_meeting">Client Meetings</option>
        <option value="document_review">Document Reviews</option>
        <option value="message">Messages</option>
      </select>

      <select
        value={filters.client}
        onChange={(e) => onChange({ ...filters, client: e.target.value })}
        className="block w-40 pl-3 pr-10 py-2 text-base border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-sm rounded-md dark:bg-gray-700"
      >
        <option value="all">All Clients</option>
        <option value="active">Active Clients</option>
        <option value="inactive">Inactive Clients</option>
      </select>
    </div>
  );
}