import React, { useState, useRef, useEffect, useContext } from "react";
import { Plus, ChevronDown, Link, PencilLine } from "lucide-react";
import { useAuthStore } from "../../store/authStore";
import Context from "../../Context";
import { usePlaidLink } from "../Link";
import { api } from "../../utils/api";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

function LinkBankButton() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const {
    plaidAuth,
    plaidTransactions,
    plaidIdentity,
    plaidBalance,
    plaidInvestments,
    plaidItem,
    plaidAccounts,
    addAccessTokenAndItemId,
  } = useAuthStore();
  const { linkToken, isPaymentInitiation, isCraProductsExclusively, dispatch } =
    useContext(Context);

  const { user } = useAuthStore();

  const onSuccess = React.useCallback(
    (public_token: string, metadata: any) => {
      // If the access_token is needed, send public_token to server
      const exchangePublicTokenForAccessToken = async () => {
        try {
          const response = await api.post("/set_access_token", {
            public_token: public_token,
          });

          if (!response.access_token) {
            throw new Error("Failed to exchange token");
          }

          dispatch({
            type: "SET_STATE",
            state: {
              itemId: response.item_id,
              accessToken: response.access_token,
              isItemAccess: true,
            },
          });

          await addAccessTokenAndItemId(response, user);
        } catch (error) {
          console.error("Token exchange failed:", error);
          dispatch({
            type: "SET_STATE",
            state: {
              itemId: `no item_id retrieved`,
              accessToken: `no access_token retrieved`,
              isItemAccess: false,
            },
          });
        }
      };
      // 'payment_initiation' products do not require the public_token to be exchanged for an access_token.
      if (isPaymentInitiation) {
        dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
      } else if (isCraProductsExclusively) {
        // When only CRA products are enabled, only user_token is needed. access_token/public_token exchange is not needed.
        dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
      } else {
        exchangePublicTokenForAccessToken();
      }

      dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
      window.history.pushState("", "", "/");
    },
    [dispatch, isPaymentInitiation, isCraProductsExclusively]
  );

  let isOauth = false;
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  const linkBankAccount = async () => {
    console.log("Ready state:", ready);
    if (!ready) {
      console.warn("Plaid Link not ready");
      return;
    }

    if (!linkToken) {
      console.error("No link token available");
      return;
    }

    console.log("Opening Plaid Link...");
    open();
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400 hover:bg-primary-500"
      >
        <Plus className="h-5 w-5 mr-2" />
        Add Account
        <ChevronDown className="h-4 w-4 ml-2" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-50">
          <div className="py-1" role="menu">
            <button
              onClick={() => linkBankAccount()}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
              role="menuitem"
            >
              <Link className="h-4 w-4 mr-3" />
              Link Bank Account
            </button>
            <button
              onClick={() => {
                document.dispatchEvent(new CustomEvent("openAddBankModal"));
                setIsOpen(false);
              }}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
              role="menuitem"
            >
              <PencilLine className="h-4 w-4 mr-3" />
              Add Manually
            </button>
            <button
              onClick={() => plaidAuth(user.access_token)}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
              role="menuitem"
            >
              Get Auth
            </button>
            <button
              onClick={() => plaidTransactions(user.access_token)}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
              role="menuitem"
            >
              Get Transactions
            </button>
            <button
              onClick={() => plaidInvestments(user.access_token)}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
              role="menuitem"
            >
              Get Investments
            </button>
            <button
              onClick={() => plaidIdentity(user.access_token)}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
              role="menuitem"
            >
              Get Identity
            </button>
            <button
              onClick={() => plaidBalance(user.access_token)}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
              role="menuitem"
            >
              Get Balance
            </button>
            {/* <button
              onClick={() => plaidHoldings(user.access_token)}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
              role="menuitem"
            >
              Get Holdings
            </button>
            <button
              onClick={() => plaidLiabilities()}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
              role="menuitem"
            >
              Get Liabilities
            </button> */}
            <button
              onClick={() => plaidItem(user.access_token)}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
              role="menuitem"
            >
              Get Item
            </button>
            <button
              onClick={() => plaidAccounts(user.access_token)}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
              role="menuitem"
            >
              Get Accounts
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LinkBankButton;
