import React from 'react';
import { CheckCircle, Clock } from 'lucide-react';

export default function TasksList() {
  const tasks = [
    {
      id: '1',
      title: 'Review Investment Portfolio',
      client: 'Sarah Johnson',
      dueDate: '2024-03-20T10:00:00Z',
      priority: 'high',
      status: 'pending',
    },
    {
      id: '2',
      title: 'Prepare Financial Report',
      client: 'Emily Parker',
      dueDate: '2024-03-21T15:30:00Z',
      priority: 'medium',
      status: 'in_progress',
    },
    // Add more mock tasks as needed
  ];

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
      <div className="p-6">
        <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
          Pending Tasks
        </h2>

        <div className="space-y-4">
          {tasks.map((task) => (
            <div
              key={task.id}
              className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
            >
              <div className="flex items-center space-x-4">
                <div className={`p-2 rounded-full ${
                  task.priority === 'high'
                    ? 'bg-red-100 text-red-600'
                    : task.priority === 'medium'
                    ? 'bg-yellow-100 text-yellow-600'
                    : 'bg-green-100 text-green-600'
                }`}>
                  <Clock className="h-5 w-5" />
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                    {task.title}
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {task.client}
                  </p>
                </div>
              </div>
              <div className="text-right">
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  {new Date(task.dueDate).toLocaleDateString()}
                </div>
                <div className={`text-sm ${
                  task.status === 'completed'
                    ? 'text-green-500'
                    : task.status === 'in_progress'
                    ? 'text-yellow-500'
                    : 'text-gray-500'
                }`}>
                  {task.status.replace('_', ' ')}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}