import React, { useState } from "react";
import { Calendar, ChevronDown } from "lucide-react";
import { format, startOfMonth, endOfMonth, subDays, isValid } from "date-fns";
import { motion, AnimatePresence } from "framer-motion";

interface DateRangeSelectorProps {
  startDate: Date;
  endDate: Date;
  onDateRangeChange: (startDate: Date, endDate: Date) => void;
  onGroupingChange: (grouping: "day" | "week" | "month") => void;
}

type DateRange = "last30" | "thisMonth" | "lastMonth" | "custom";

function DateRangeSelector({
  startDate,
  endDate,
  onDateRangeChange,
  onGroupingChange,
}: DateRangeSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState<DateRange>("last30");
  const [customStartDate, setCustomStartDate] = useState(
    format(startDate, "yyyy-MM-dd")
  );
  const [customEndDate, setCustomEndDate] = useState(
    format(endDate, "yyyy-MM-dd")
  );

  const handleRangeSelect = (range: DateRange) => {
    setSelectedRange(range);
    const today = new Date();

    switch (range) {
      case "last30":
        onDateRangeChange(subDays(today, 30), today);
        break;
      case "thisMonth":
        onDateRangeChange(startOfMonth(today), today);
        break;
      case "lastMonth":
        const lastMonth = subDays(startOfMonth(today), 1);
        onDateRangeChange(startOfMonth(lastMonth), endOfMonth(lastMonth));
        break;
      case "custom":
        // Don't change dates, just show custom inputs
        break;
    }

    if (range !== "custom") {
      setIsOpen(false);
    }
  };

  const handleCustomDateSubmit = () => {
    const newStartDate = new Date(customStartDate);
    const newEndDate = new Date(customEndDate);

    if (
      isValid(newStartDate) &&
      isValid(newEndDate) &&
      newStartDate <= newEndDate
    ) {
      onDateRangeChange(newStartDate, newEndDate);
      setIsOpen(false);
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 flex items-center justify-between">
      <div className="flex items-center">
        <Calendar className="h-5 w-5 text-primary-400 mr-2" />
        <div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Showing transactions from
          </h3>
          <p className="text-lg font-semibold text-gray-900 dark:text-white">
            {format(startDate, "MMMM d, yyyy")} -{" "}
            {format(endDate, "MMMM d, yyyy")}
          </p>
        </div>
      </div>

      <div className="relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400"
        >
          {selectedRange === "custom"
            ? "Custom Range"
            : selectedRange === "last30"
            ? "Last 30 Days"
            : selectedRange === "thisMonth"
            ? "This Month"
            : "Last Month"}
          <ChevronDown
            className={`ml-2 h-4 w-4 transition-transform ${
              isOpen ? "transform rotate-180" : ""
            }`}
          />
        </button>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="absolute right-0 mt-2 w-72 bg-white dark:bg-gray-800 rounded-lg shadow-lg z-50 p-4"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="space-y-2">
                <button
                  onClick={() => handleRangeSelect("last30")}
                  className={`w-full text-left px-4 py-2 rounded-lg ${
                    selectedRange === "last30"
                      ? "bg-primary-50 dark:bg-primary-900/20 text-primary-400"
                      : "hover:bg-gray-100 dark:hover:bg-gray-700"
                  }`}
                >
                  Last 30 Days
                </button>
                <button
                  onClick={() => handleRangeSelect("thisMonth")}
                  className={`w-full text-left px-4 py-2 rounded-lg ${
                    selectedRange === "thisMonth"
                      ? "bg-primary-50 dark:bg-primary-900/20 text-primary-400"
                      : "hover:bg-gray-100 dark:hover:bg-gray-700"
                  }`}
                >
                  This Month
                </button>
                <button
                  onClick={() => handleRangeSelect("lastMonth")}
                  className={`w-full text-left px-4 py-2 rounded-lg ${
                    selectedRange === "lastMonth"
                      ? "bg-primary-50 dark:bg-primary-900/20 text-primary-400"
                      : "hover:bg-gray-100 dark:hover:bg-gray-700"
                  }`}
                >
                  Last Month
                </button>
                <button
                  onClick={() => handleRangeSelect("custom")}
                  className={`w-full text-left px-4 py-2 rounded-lg ${
                    selectedRange === "custom"
                      ? "bg-primary-50 dark:bg-primary-900/20 text-primary-400"
                      : "hover:bg-gray-100 dark:hover:bg-gray-700"
                  }`}
                >
                  Custom Range
                </button>
              </div>

              {selectedRange === "custom" && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  className="mt-4 space-y-4"
                >
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Start Date
                    </label>
                    <input
                      type="date"
                      value={customStartDate}
                      onChange={(e) => setCustomStartDate(e.target.value)}
                      className="w-full rounded-lg border border-gray-300 dark:border-gray-600 px-3 py-2 focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700"
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      End Date
                    </label>
                    <input
                      type="date"
                      value={customEndDate}
                      onChange={(e) => setCustomEndDate(e.target.value)}
                      className="w-full rounded-lg border border-gray-300 dark:border-gray-600 px-3 py-2 focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700"
                    />
                  </div>
                  <button
                    onClick={handleCustomDateSubmit}
                    className="w-full px-4 py-2 bg-primary-400 text-white rounded-lg hover:bg-primary-500"
                  >
                    Apply Custom Range
                  </button>
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default DateRangeSelector;
