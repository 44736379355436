import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { TrendingUp, TrendingDown } from "lucide-react";
import DownloadButton from "./DownloadButton";
import QualitativeInsights from "./QualitativeInsights";
import { useTransactionStore } from "../../store/transactionStore";
import { format, isWithinInterval, startOfYear, sub } from "date-fns";

interface IncomeExpenseReportProps {
  timeFrame: string;
}

function IncomeExpenseReport({ timeFrame }: IncomeExpenseReportProps) {
  const { transactions } = useTransactionStore();

  const getDateRange = (timeFrame: string) => {
    const endDate = new Date();
    let startDate: Date;

    switch (timeFrame) {
      case "1M":
        startDate = sub(endDate, { months: 1 });
        break;
      case "3M":
        startDate = sub(endDate, { months: 3 });
        break;
      case "6M":
        startDate = sub(endDate, { months: 6 });
        break;
      case "YTD":
        startDate = startOfYear(endDate);
        break;
      case "1Y":
        startDate = sub(endDate, { years: 1 });
        break;
      case "5Y":
        startDate = sub(endDate, { years: 5 });
        break;
      case "ALL":
      default:
        startDate = new Date(0); // Include all transactions
        break;
    }

    return { startDate, endDate };
  };

  const { startDate, endDate } = getDateRange(timeFrame);

  const filteredTransactions = transactions.filter((transaction) =>
    isWithinInterval(new Date(transaction.date), {
      start: startDate,
      end: endDate,
    })
  );

  const calculateMonthlyData = () => {
    // Group transactions by month
    const monthlyData = filteredTransactions.reduce(
      (acc: { [key: string]: { income: number; expenses: number } }, tx) => {
        const month = format(new Date(tx.date), "MMM"); // Format month as "Jan", "Feb", etc.
        if (!acc[month]) {
          acc[month] = { income: 0, expenses: 0 };
        }
        if (tx.amount > 0) {
          acc[month].income += tx.amount; // Add income
        } else {
          acc[month].expenses += Math.abs(tx.amount); // Add expenses as positive value
        }
        return acc;
      },
      {}
    );

    // Format the data for the chart
    return Object.entries(monthlyData).map(([month, { income, expenses }]) => ({
      month,
      income: income.toLocaleString(), // Ensure numbers are passed as-is for visualization
      expenses: expenses.toFixed(2), // Ensure numbers are passed as-is for visualization
    }));
  };

  const monthlyData = calculateMonthlyData();

  const calculateMonthlyExpenses = () =>
    filteredTransactions
      .filter((tx) => tx.amount < 0)
      .reduce((sum, tx) => sum + Math.abs(tx.amount), 0);

  const calculateMonthlyIncome = () =>
    filteredTransactions
      .filter((tx) => tx.amount > 0)
      .reduce((sum, tx) => sum + tx.amount, 0);

  const monthlyIncome = calculateMonthlyIncome();

  const monthlyExpenses = calculateMonthlyExpenses();

  const monthlySavings = monthlyIncome - monthlyExpenses;

  // const categoryData = [
  //   { category: "Salary", amount: 6500 },
  //   { category: "Investments", amount: 500 },
  //   { category: "Side Hustle", amount: 800 },
  //   { category: "Other", amount: 200 },
  // ];

  const calculateIncomeByCategory = () => {
    const incomeByCategory = filteredTransactions.reduce(
      (acc: { [key: string]: number }, transaction) => {
        if (transaction.amount >= 0) {
          const categoryParts = transaction.category; // Split categories by commas
          const topLevelCategory = categoryParts[1]; // Use the top-level category
          acc[topLevelCategory] =
            (acc[topLevelCategory] || 0) + transaction.amount; // Sum the income for each top-level category
        }
        return acc;
      },
      {}
    );

    // Format the data for the chart
    return Object.entries(incomeByCategory).map(([category, amount]) => ({
      category,
      amount: parseFloat(amount.toFixed(2)),
    }));
  };

  const categoryData = calculateIncomeByCategory();

  function getLargestIncomeCategory() {
    // Group income by category
    const incomeByCategory = filteredTransactions.reduce(
      (acc: { [key: string]: number }, transaction) => {
        const category = transaction.category[1]; // Only consider the top-level category
        if (transaction.amount > 0) {
          acc[category] = (acc[category] || 0) + transaction.amount; // Summing positive amounts (income)
        }
        return acc;
      },
      {}
    );

    // Find the category with the largest income
    return Object.entries(incomeByCategory).reduce(
      (largest, [category, amount]) =>
        amount > largest.amount ? { category, amount } : largest,
      { category: "", amount: 0 }
    );
  }

  // Get the largest income category
  const largestIncomeCategory = getLargestIncomeCategory();

  // Calculate total income (instead of spending) if needed
  const totalIncome = filteredTransactions
    .reduce(
      (acc, transaction) =>
        transaction.amount > 0 ? acc + transaction.amount : acc,
      0
    )
    .toFixed(2);

  // Calculate the percentage of the largest income category relative to total income
  const largestIncomePercentage = (
    (largestIncomeCategory.amount / parseFloat(totalIncome)) *
    100
  ).toFixed(2);

  const insights = [
    {
      type: monthlySavings >= 0 ? ("positive" as const) : ("negative" as const),
      title:
        monthlySavings >= 0 ? "Positive Savings Rate" : "Negative Savings Rate",
      description:
        monthlySavings >= 0
          ? "Your savings are positive! keep it up. Savings: $" +
            Math.abs(monthlySavings).toLocaleString()
          : "Your expenses are higher than your income. The difference is: $" +
            Math.abs(monthlySavings).toLocaleString(),
    },
    {
      type: "warning" as const,
      title: "Income",
      description:
        "The largest income is " +
        largestIncomeCategory.category +
        " at " +
        parseInt(largestIncomePercentage) +
        "% of total income.",
    },
    // {
    //   type: "action" as const,
    //   title: "Investment Opportunity",
    //   description:
    //     "With consistent surplus, consider increasing investment contributions.",
    // },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Income vs Expenses</h3>
        <DownloadButton
          reportId="income-expense-report"
          fileName="income-expense-analysis"
        />
      </div>

      <div id="income-expense-report" className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Monthly Income
            </h4>
            <p className="text-2xl font-bold text-green-500">
              ${monthlyIncome.toLocaleString()}
            </p>
            {/* <div className="flex items-center mt-2 text-green-500">
              <TrendingUp className="h-4 w-4 mr-1" />
              <span>+10.5% from last month</span>
            </div> */}
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Monthly Expenses
            </h4>
            <p className="text-2xl font-bold text-red-500">
              ${monthlyExpenses.toLocaleString()}
            </p>
            {/* <div className="flex items-center mt-2 text-red-500">
              <TrendingDown className="h-4 w-4 mr-1" />
              <span>+4.8% from last month</span>
            </div> */}
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Monthly Savings
            </h4>

            {monthlySavings >= 0 ? (
              <p className="text-2xl font-bold text-green-500">
                ${monthlySavings.toLocaleString()}
              </p>
            ) : (
              <p className="text-2xl font-bold text-red-500">
                -${Math.abs(monthlySavings).toLocaleString()}
              </p>
            )}
            {/* <p className="text-sm text-gray-500 mt-2">46.25% savings rate</p> */}
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Income vs Expenses Trend
            </h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={monthlyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  {/* Income Area */}
                  <Area
                    type="monotone"
                    dataKey="income"
                    stackId="1" // Ensure stacking
                    stroke="#10B981"
                    fill="#10B981"
                    fillOpacity={0.3}
                  />
                  {/* Expenses Area */}
                  <Area
                    type="monotone"
                    dataKey="expenses"
                    stackId="1" // Ensure stacking
                    stroke="#EF4444"
                    fill="#EF4444"
                    fillOpacity={0.3}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
              Income Sources
            </h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={categoryData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="category" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="amount" fill="#B100FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">
            Insights & Recommendations
          </h4>
          <QualitativeInsights insights={insights} />
        </div>
      </div>
    </div>
  );
}

export default IncomeExpenseReport;
