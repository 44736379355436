import React, { useState, useCallback } from "react";
import { useClientStore } from "../../store/clientStore";
import { ClientJourney } from "../../types/client";
import ClientDetailsModal from "../../components/admin/clients/ClientDetailsModal";
import PhaseColumn from "../../components/admin/PhaseColumn";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  defaultDropAnimationSideEffects,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";

const sessions = ["1", "2", "3", "4", "5", "6", "7"];

const dropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

export default function ClientsTab() {
  const { journeys, updateJourneyPhase } = useClientStore();
  const [selectedJourney, setSelectedJourney] = useState<ClientJourney | null>(
    null
  );
  const [activeId, setActiveId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
        delay: 0,
        tolerance: 0,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const journeyId = String(active.id);
        const newSession = String(over.id);
        const journey = journeys.find((j) => j.id === journeyId);

        if (journey) {
          updateJourneyPhase(journey.userId, newSession as any);
        }
      }

      setActiveId(null);
    },
    [journeys, updateJourneyPhase]
  );

  const getJourneysBySession = useCallback(
    (session: string) => {
      return journeys.filter((journey) => journey.phase === session);
    },
    [journeys]
  );

  const activeJourney = journeys.find((j) => j.id === activeId);

  console.log(journeys);

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
        Client Sessions
      </h2>

      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <div className="grid grid-cols-7 gap-4">
          {sessions.map((session) => (
            <PhaseColumn
              key={session}
              phase={session}
              journeys={getJourneysBySession(session)}
              onJourneyClick={setSelectedJourney}
            />
          ))}
        </div>

        <DragOverlay dropAnimation={dropAnimation}>
          {activeId && activeJourney ? (
            <div className="bg-white dark:bg-gray-700 rounded-lg shadow-2xl p-4 transform scale-105 opacity-90 pointer-events-none">
              <div className="flex items-center space-x-3">
                <img
                  src={activeJourney.user.avatar}
                  alt={activeJourney.user.name}
                  className="h-10 w-10 rounded-full"
                />
                <div>
                  <h4 className="font-medium text-gray-900 dark:text-white">
                    {activeJourney.user.name}
                  </h4>
                  <div className="flex items-center mt-1">
                    <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-1.5">
                      <div
                        className="bg-primary-400 h-1.5 rounded-full"
                        style={{ width: `${activeJourney.progress}%` }}
                      />
                    </div>
                    <span className="ml-2 text-xs text-gray-500 dark:text-gray-400">
                      {activeJourney.progress}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>

      {selectedJourney && (
        <ClientDetailsModal
          journey={selectedJourney}
          onClose={() => setSelectedJourney(null)}
          onPhaseChange={(newPhase) => {
            updateJourneyPhase(selectedJourney.userId, newPhase);
          }}
        />
      )}
    </div>
  );
}
