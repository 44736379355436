import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate, Link } from "react-router-dom";
import { useAuthStore } from "../../store/authStore";
import { Wallet, EyeOff, Eye } from "lucide-react";
import { loginWithEmail } from "../../utils/firebase-auth";

// Force light mode styles for login page
import "../../styles/auth.css";

const loginSchema = z.object({
  email: z.string().email("Invalid email address"),
  password: z.string().min(6, "Password must be at least 6 characters"),
});

type LoginFormData = z.infer<typeof loginSchema>;

function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [firebaseError, setFirebaseError] = useState("");
  const {
    isAuthenticated,
    error,
    clearError,
    login: loginUser,
  } = useAuthStore();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    return () => clearError();
  }, [isAuthenticated, navigate, clearError]);

  const onSubmit = async (data: LoginFormData) => {
    try {
      setFirebaseError("");
      const result = await loginUser(data.email, data.password);
      if (result === "admin") {
        navigate(`/${result}`);
      }
    } catch (error) {
      setFirebaseError("Invalid Email or Password");
    }
  };

  return (
    <div className="auth-container min-h-screen bg-gradient-to-br from-primary-50 to-white flex flex-col justify-center px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex flex-col items-center">
          <div className="rounded-full bg-white/90 p-2.5 shadow-lg">
            <img
              src="https://i.imghippo.com/files/BlL8008IHU.png"
              alt="Logo"
              className="h-10 w-10 sm:h-12 sm:w-12"
            />
          </div>
          <h2 className="mt-4 sm:mt-8 text-center text-3xl sm:text-4xl font-bold tracking-tight text-gray-900">
            Welcome back
          </h2>
        </div>
      </div>

      <div className="mt-4 sm:mt-8 mx-auto w-full max-w-[min(400px,calc(100%-2rem))] sm:max-w-md">
        <div className="bg-white/90 backdrop-blur-xl py-5 px-4 shadow-2xl rounded-2xl sm:py-8 sm:px-10">
          {firebaseError && (
            <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
              {firebaseError}
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-900">
                Email address
              </label>
              <input
                type="email"
                {...register("email")}
                className="mt-1.5 sm:mt-2 block w-full rounded-xl border border-gray-200 shadow-sm focus:ring-2 focus:ring-[#B100FF] focus:ring-opacity-30 focus:border-[#B100FF] focus:outline-none text-gray-900 text-sm sm:text-base px-3 py-2.5 sm:px-4 sm:py-3.5 bg-white"
                placeholder="sarah@example.com"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.email.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-900">
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  className="block w-full rounded-xl border border-gray-200 shadow-sm focus:ring-2 focus:ring-[#B100FF] focus:ring-opacity-30 focus:border-[#B100FF] focus:outline-none text-gray-900 text-sm sm:text-base px-3 py-2.5 sm:px-4 sm:py-3.5 pr-12 bg-white"
                  placeholder="••••••••"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-500 hover:text-gray-700"
                >
                  {showPassword ? (
                    <EyeOff className="h-6 w-6" />
                  ) : (
                    <Eye className="h-6 w-6" />
                  )}
                </button>
              </div>
              {errors.password && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.password.message}
                </p>
              )}
            </div>
            <div className="flex flex-row sm:flex-row items-center justify-between space-x-4 sm:space-y-0">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 sm:h-5 sm:w-5 text-[#B100FF] focus:ring-0 border-gray-200 rounded-md bg-white cursor-pointer accent-[#B100FF]"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 sm:ml-3 block text-sm sm:text-base text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a
                  href="#"
                  className="text-left text-sm sm:text-base font-medium text-primary-400 hover:text-primary-500 transition-colors"
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full flex justify-center py-3 sm:py-4 px-4 border border-transparent rounded-xl shadow-lg text-sm sm:text-base font-semibold text-white bg-[#B100FF] hover:bg-[#9d00e6] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#B100FF] disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 hover:shadow-xl"
            >
              <span className="text-white">
                {isSubmitting ? "Signing in..." : "Sign in"}
              </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
