import React from 'react';
import { format } from 'date-fns';
import { 
  UserPlus, 
  Clock, 
  AlertTriangle,
  CheckCircle,
  XCircle,
  MessageSquare,
  FileText
} from 'lucide-react';
import { AdminNotification } from '../../../store/notificationStore';

interface NotificationItemProps {
  notification: AdminNotification;
  onRead: (id: string) => void;
  onRemove: (id: string) => void;
}

export default function NotificationItem({ 
  notification, 
  onRead, 
  onRemove 
}: NotificationItemProps) {
  const getIcon = () => {
    switch (notification.type) {
      case 'access':
        return <UserPlus className="h-5 w-5 text-blue-500" />;
      case 'task':
        return <Clock className="h-5 w-5 text-yellow-500" />;
      case 'expiration':
        return <AlertTriangle className="h-5 w-5 text-red-500" />;
      case 'message':
        return <MessageSquare className="h-5 w-5 text-green-500" />;
      case 'document':
        return <FileText className="h-5 w-5 text-purple-500" />;
      default:
        return <AlertTriangle className="h-5 w-5 text-gray-400" />;
    }
  };

  const getPriorityColor = () => {
    switch (notification.priority) {
      case 'high':
        return 'bg-red-50 dark:bg-red-900/20';
      case 'medium':
        return 'bg-yellow-50 dark:bg-yellow-900/20';
      default:
        return 'bg-gray-50 dark:bg-gray-800';
    }
  };

  return (
    <div 
      className={`p-4 ${notification.read ? 'bg-white dark:bg-gray-800' : getPriorityColor()} hover:bg-gray-50 dark:hover:bg-gray-700`}
    >
      <div className="flex items-start">
        <div className="flex-shrink-0">{getIcon()}</div>
        <div className="ml-3 flex-1">
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900 dark:text-white">
              {notification.title}
            </p>
            <div className="flex items-center space-x-2">
              {!notification.read && (
                <button
                  onClick={() => onRead(notification.id)}
                  className="text-primary-400 hover:text-primary-500"
                  title="Mark as read"
                >
                  <CheckCircle className="h-4 w-4" />
                </button>
              )}
              <button
                onClick={() => onRemove(notification.id)}
                className="text-gray-400 hover:text-red-500"
                title="Remove notification"
              >
                <XCircle className="h-4 w-4" />
              </button>
            </div>
          </div>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            {notification.message}
          </p>
          <p className="mt-1 text-xs text-gray-400">
            {format(new Date(notification.timestamp), 'MMM d, h:mm a')}
          </p>
          {notification.metadata?.actionUrl && (
            <a
              href={notification.metadata.actionUrl}
              className="mt-2 inline-flex items-center text-sm text-primary-400 hover:text-primary-500"
            >
              View Details →
            </a>
          )}
        </div>
      </div>
    </div>
  );
}