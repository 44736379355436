import React, { useState } from "react";
import { format, isWithinInterval } from "date-fns";
import { ArrowRight, Search } from "lucide-react";
import { Transaction } from "../../types/transaction";
import { user_info } from "../../../server/tokens.js";

interface TransactionsListProps {
  transactions: Transaction[];
  onTransactionClick: (transaction: Transaction) => void;
  dateRange: { startDate: Date; endDate: Date };
}

const ITEMS_PER_PAGE = 15;

function TransactionsList({
  transactions,
  onTransactionClick,
  dateRange,
}: TransactionsListProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [displayCount, setDisplayCount] = useState(ITEMS_PER_PAGE);

  const currentTransactions = transactions.filter((t) =>
    isWithinInterval(new Date(t.date), {
      start:
        dateRange.startDate instanceof Date
          ? dateRange.startDate
          : new Date(dateRange.startDate),
      end:
        dateRange.endDate instanceof Date
          ? dateRange.endDate
          : new Date(dateRange.endDate),
    })
  );

  // Filter transactions based on search query

  const filteredTransactions = currentTransactions.filter(
    (transaction: any) => {
      const searchLower = searchQuery.toLowerCase();

      return (
        transaction.merchant_name?.toLowerCase().includes(searchLower) ||
        transaction.amount.toString().includes(searchLower) ||
        transaction.personal_finance_category.primary
          .toLowerCase()
          .includes(searchLower) ||
        transaction.authorized_date.includes(searchLower)
      );
    }
  );

  // Get transactions to display based on current page
  const displayedTransactions = filteredTransactions.slice(0, displayCount);

  const handleLoadMore = () => {
    setDisplayCount((prev) => prev + ITEMS_PER_PAGE);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      {/* Search Bar */}
      <div className="p-4 border-b border-gray-200 dark:border-gray-700">
        <div className="relative">
          <input
            type="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search transactions by merchant, amount, category..."
            className="w-full pl-10 pr-4 py-2 bg-gray-50 dark:bg-gray-700 border-0 rounded-lg focus:ring-0 focus:outline-none text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      <div className="overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Date & Time
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Description
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                View More
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {displayedTransactions.map((transaction) => (
              <tr
                key={transaction.id}
                className="hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
                onClick={() => onTransactionClick(transaction)}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900 dark:text-white">
                    {transaction?.authorized_date
                      ? format(
                          new Date(transaction.authorized_date),
                          "MMM d, yyyy"
                        )
                      : "N/A"}
                  </div>
                  {/* <div className="text-sm text-gray-500">
                    {format(
                      new Date(
                        `${transaction.authorized_date}T${transaction.time}`
                      ),
                      "h:mm a"
                    )}
                  </div> */}
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    {transaction.logo_url ? (
                      <img
                        src={transaction.logo_url}
                        alt={transaction.merchant_name}
                        className="h-8 w-8 rounded-full mr-3"
                      />
                    ) : (
                      <div className="h-8 w-8 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center mr-3">
                        <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
                          {transaction?.merchant_name?.charAt(0)}
                        </span>
                      </div>
                    )}
                    <div>
                      <div className="text-sm font-medium text-gray-900 dark:text-white">
                        {transaction.merchant_name}
                      </div>
                      <div className="text-sm text-gray-500">
                        Purchased From {transaction.merchant_name}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right">
                  <div
                    className={`text-sm font-medium ${
                      transaction.amount < 0 ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {transaction.amount < 0
                      ? "-$" + (transaction.amount * -1).toLocaleString()
                      : "$" + transaction.amount.toLocaleString()}
                  </div>
                  {/* {transaction.currency !== "USD" && (
                    <div className="text-xs text-gray-500">
                      {transaction.currency}{" "}
                      {transaction.usdAmount?.toLocaleString()}
                    </div>
                  )} */}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right">
                  <button className="text-primary-400 hover:text-primary-500">
                    <ArrowRight className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Load More Button */}
      {displayCount < filteredTransactions.length && (
        <div className="p-4 border-t border-gray-200 dark:border-gray-700">
          <button
            onClick={handleLoadMore}
            className="w-full px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
          >
            Load More ({filteredTransactions.length - displayCount} remaining)
          </button>
        </div>
      )}
    </div>
  );
}

export default TransactionsList;
