import React from "react";
import {
  ArrowUpRight,
  ArrowDownLeft,
  TrendingUp,
  DollarSign,
} from "lucide-react";
import { Transaction } from "../../types/transaction";
import { transactions } from "../../../server/transactions.js";

interface TransactionSummaryProps {
  transactions: Transaction[];
}

function TransactionSummary() {
  const totalIncome = transactions
    .filter((t) => t.amount >= 0)
    .reduce((sum, t) => sum + t.amount, 0);

  const totalExpenses = transactions
    .filter((t) => t.amount < 0)
    .reduce((sum, t) => sum + t.amount, 0);

  const netAmount = totalIncome - totalExpenses;

  const largestTransaction = transactions.reduce(
    (max, t) => (t.amount > max.amount ? t : max),
    transactions[0]
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium">Total Income</h3>
          <ArrowDownLeft className="h-5 w-5 text-green-500" />
        </div>
        <p className="text-2xl font-bold text-green-500">
          +${totalIncome.toLocaleString()}
        </p>
        <p className="text-sm text-gray-500 mt-2">This month</p>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium">Total Expenses</h3>
          <ArrowUpRight className="h-5 w-5 text-red-500" />
        </div>
        <p className="text-2xl font-bold text-red-500">
          -${Math.abs(totalExpenses).toLocaleString()}
        </p>
        <p className="text-sm text-gray-500 mt-2">This month</p>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium">Net Amount</h3>
          <TrendingUp className="h-5 w-5 text-primary-400" />
        </div>
        <p
          className={`text-2xl font-bold ${
            netAmount >= 0 ? "text-green-500" : "text-red-500"
          }`}
        >
          {netAmount >= 0 ? "+" : "-"}${Math.abs(netAmount).toLocaleString()}
        </p>
        <p className="text-sm text-gray-500 mt-2">Net change</p>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium">Largest Transaction</h3>
          <DollarSign className="h-5 w-5 text-primary-400" />
        </div>
        {largestTransaction && (
          <>
            <p className="text-2xl font-bold">
              ${largestTransaction.amount.toLocaleString()}
            </p>
            <p className="text-sm text-gray-500 mt-2">
              {largestTransaction.merchant_name}
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default TransactionSummary;
