import React, { memo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ClientJourney } from "../../types/client";

interface SortableClientCardProps {
  journey: ClientJourney;
  onClick: () => void;
}

const SortableClientCard = memo(
  ({ journey, onClick }: SortableClientCardProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: journey.id,
      data: journey,
    });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
    };

    return (
      <div
        ref={setNodeRef}
        style={style}
        className={`${isDragging ? "z-50" : ""}`}
        {...attributes}
        {...listeners}
      >
        <div
          onClick={onClick}
          className={`bg-white dark:bg-gray-700 rounded-lg shadow p-4 transition-all duration-200 will-change-transform ${
            isDragging ? "shadow-xl scale-105 opacity-90" : "hover:shadow-md"
          }`}
        >
          <div className="flex items-center space-x-3">
            <img
              src={journey.user.avatar}
              alt={journey.user.name}
              className="h-10 w-10 rounded-full"
              loading="lazy"
            />
            <div>
              <h4 className="font-medium text-gray-900 dark:text-white">
                {journey.user.name}
              </h4>
              <div className="flex items-center mt-1">
                <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-1.5">
                  <div
                    className="bg-primary-400 h-1.5 rounded-full will-change-transform"
                    style={{ width: `${journey.progress}%` }}
                  />
                </div>
                <span className="ml-2 text-xs text-gray-500 dark:text-gray-400">
                  {journey.progress}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

SortableClientCard.displayName = "SortableClientCard";

export default SortableClientCard;
