export const userAccounts = {
  accounts: [
    {
      account_id: "nEL73oZg1GcJ8PJXd996cezjXxKNmDfADZLnk",
      balances: {
        available: 100,
        current: 110,
        iso_currency_code: "USD",
      },
      mask: "0000",
      official_name: "Plaid Gold Standard 0% Interest Checking",
      persistent_account_id:
        "8cfb8beb89b774ee43b090625f0d61d0814322b43bff984eaf60386e",
      subtype: "checking",
      type: "depository",
    },
    {
      account_id: "bEdK94BNQGc8Ql8Zykk3UemZorNAnVfmPEMdj",
      balances: {
        available: 200,
        current: 210,
        iso_currency_code: "USD",
      },
      mask: "1111",
      official_name: "Plaid Silver Standard 0.1% Interest Saving",
      persistent_account_id:
        "211a4e5d8361a3afb7a3886362198c7306e00a313b5aa944c20d34b6",
      subtype: "savings",
      type: "depository",
    },
    {
      account_id: "my1jBpQoZGfj6NjZyxxDhmwDlvMn9VCgk9VWa",
      balances: {
        available: 12060,
        current: 12060,
        iso_currency_code: "USD",
        limit: null,
        unofficial_currency_code: null,
      },
      mask: "9002",
      name: "Plaid Cash Management",
      official_name: "Plaid Growth Cash Management",
      subtype: "cash management",
      type: "depository",
    },
  ],
  item: {
    auth_method: "INSTANT_AUTH",
    available_products: [
      "assets",
      "balance",
      "signal",
      "identity_match",
      "income_verification",
      "investments",
      "recurring_transactions",
      "transfer",
    ],
    billed_products: ["auth", "identity", "investments_auth", "transactions"],
    consent_expiration_time: null,
    consented_products: [
      "assets",
      "auth",
      "identity",
      "identity_match",
      "income_verification",
      "investments",
      "investments_auth",
      "transactions",
      "transfer",
    ],
    error: null,
    institution_id: "ins_11",
    institution_name: "Charles Schwab",
    item_id: "XPXADlyqEeT37j38pJJdTPVMazPVw8CdbX7RD",
    products: [
      "assets",
      "auth",
      "identity",
      "investments_auth",
      "transactions",
    ],
    update_type: "background",
    webhook: "",
  },
  request_id: "Az4N0E2L5owjIln",
};
