import React from "react";
import { Plus } from "lucide-react";
import { BudgetItem } from "../../types/budget";

interface BudgetOverviewProps {
  budgetItems?: BudgetItem[];
}

function BudgetOverview({ budgetItems = [] }: BudgetOverviewProps) {
  const totalBudget = budgetItems.reduce((sum, item) => sum + item.limit, 0);
  const totalSpent = budgetItems.reduce((sum, item) => sum + item.spent, 0);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
          Budget Overview
        </h2>
        <button className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-primary-400 hover:bg-primary-500 rounded-md">
          <Plus className="h-5 w-5 mr-2" />
          Add Category
        </button>
      </div>

      <div className="mb-6">
        <div className="flex justify-between mb-2">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Total Budget
          </span>
          <span className="text-sm font-medium text-gray-900 dark:text-white">
            ${totalBudget.toLocaleString()}
          </span>
        </div>
        <div className="flex justify-between mb-2">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Total Spent
          </span>
          <span className="text-sm font-medium text-gray-900 dark:text-white">
            ${totalSpent.toLocaleString()}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Remaining
          </span>
          <span className="text-sm font-medium text-gray-900 dark:text-white">
            ${(totalBudget - totalSpent).toLocaleString()}
          </span>
        </div>
      </div>

      <div className="space-y-4">
        {budgetItems.map((item) => {
          const percentage = (item.spent / item.limit) * 100;
          const isOverBudget = item.spent > item.limit;

          return (
            <div key={item.id} className="space-y-2">
              <div className="flex justify-between items-center">
                <span className="font-medium text-gray-900 dark:text-white capitalize">
                  {item.name}
                </span>
                <span
                  className={`text-sm font-medium ${
                    isOverBudget ? "text-red-500" : "text-green-500"
                  }`}
                >
                  ${item.spent.toLocaleString()} of $
                  {item.limit.toLocaleString()}
                </span>
              </div>
              <div className="relative h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                <div
                  className={`absolute top-0 left-0 h-full transition-all duration-500 rounded-full ${
                    isOverBudget
                      ? "bg-red-500"
                      : percentage >= 80
                      ? "bg-yellow-500"
                      : "bg-green-500"
                  }`}
                  style={{ width: `${Math.min(percentage, 100)}%` }}
                />
              </div>
              <div className="flex justify-between text-xs text-gray-500">
                <span>{percentage.toFixed(1)}% spent</span>
                <span>
                  ${(item.limit - item.spent).toLocaleString()} remaining
                </span>
              </div>
            </div>
          );
        })}

        {budgetItems.length === 0 && (
          <div className="text-center py-8">
            <p className="text-gray-500 dark:text-gray-400 mb-4">
              No budget categories yet. Add some to get started!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default BudgetOverview;
