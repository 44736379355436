import { useEffect, useCallback, useState } from "react";

// Types
interface PlaidAccount {
  id: string;
  name: string;
  mask: string;
  type: string;
  subtype: string;
  verification_status: string;
}

interface PlaidInstitution {
  name: string;
  institution_id: string;
}

interface PlaidLinkError {
  error_type: string;
  error_code: string;
  error_message: string;
  display_message: string;
}

interface PlaidLinkOnSuccessMetadata {
  institution: null | PlaidInstitution;
  accounts: Array<PlaidAccount>;
  link_session_id: string;
  transfer_status?: string;
}

interface PlaidLinkOnExitMetadata {
  institution: null | PlaidInstitution;
  status: null | string;
  link_session_id: string;
  request_id: string;
}

interface PlaidLinkOnEventMetadata {
  error_type: null | string;
  error_code: null | string;
  error_message: null | string;
  exit_status: null | string;
  institution_id: null | string;
  institution_name: null | string;
  institution_search_query: null | string;
  mfa_type: null | string;
  view_name: null | string;
  selection: null | string;
  timestamp: string;
  link_session_id: string;
  request_id: string;
}

type PlaidLinkOnSuccess = (
  public_token: string,
  metadata: PlaidLinkOnSuccessMetadata
) => void;

type PlaidLinkOnExit = (
  error: null | PlaidLinkError,
  metadata: PlaidLinkOnExitMetadata
) => void;

enum PlaidLinkStableEvent {
  OPEN = "OPEN",
  EXIT = "EXIT",
  HANDOFF = "HANDOFF",
  SELECT_INSTITUTION = "SELECT_INSTITUTION",
  ERROR = "ERROR",
  BANK_INCOME_INSIGHTS_COMPLETED = "BANK_INCOME_INSIGHTS_COMPLETED",
  IDENTITY_VERIFICATION_PASS_SESSION = "IDENTITY_VERIFICATION_PASS_SESSION",
  IDENTITY_VERIFICATION_FAIL_SESSION = "IDENTITY_VERIFICATION_FAIL_SESSION",
}

type PlaidLinkOnEvent = (
  eventName: PlaidLinkStableEvent | string,
  metadata: PlaidLinkOnEventMetadata
) => void;

type PlaidLinkOnLoad = () => void;

interface CommonPlaidLinkOptions<T> {
  onSuccess: T;
  onExit?: PlaidLinkOnExit;
  onLoad?: PlaidLinkOnLoad;
  onEvent?: PlaidLinkOnEvent;
}

interface PlaidLinkOptionsWithPublicKey
  extends CommonPlaidLinkOptions<PlaidLinkOnSuccess> {
  publicKey: string;
  token?: string;
  clientName: string;
  env: string;
  product: Array<string>;
  countryCodes?: Array<string>;
  language?: string;
  userEmailAddress?: string;
  userLegalName?: string;
  webhook?: string;
  linkCustomizationName?: string;
  accountSubtypes?: {
    [key: string]: Array<string>;
  };
  oauthNonce?: string;
  oauthRedirectUri?: string;
  oauthStateId?: string;
  paymentToken?: string;
}

interface PlaidLinkOptionsWithLinkToken
  extends CommonPlaidLinkOptions<PlaidLinkOnSuccess> {
  token: string | null;
  receivedRedirectUri?: string;
}

type PlaidLinkOptions =
  | PlaidLinkOptionsWithPublicKey
  | PlaidLinkOptionsWithLinkToken;

interface PlaidHandler {
  open: () => void;
  exit: (force?: boolean) => void;
  destroy: () => void;
}

interface PlaidEmbeddedHandler {
  destroy: () => void;
}

interface Plaid extends PlaidHandler {
  create: (config: PlaidLinkOptions) => PlaidHandler;
  createEmbedded: (
    config: PlaidLinkOptions,
    domTarget: HTMLElement
  ) => PlaidEmbeddedHandler;
}

// Add Plaid to Window type
declare global {
  interface Window {
    Plaid: Plaid;
  }
}

// Hook implementation
export const usePlaidLink = (options: PlaidLinkOptions) => {
  const [error, setError] = useState<ErrorEvent | null>(null);
  const [ready, setReady] = useState(false);
  const [plaidHandler, setPlaidHandler] = useState<any>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";
    script.async = true;
    script.onload = () => setReady(true);
    script.onerror = (err) => setError(err);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!ready || !("token" in options && options.token)) return;

    const handler = window.Plaid.create({
      ...options,
      onSuccess: (
        public_token: string,
        metadata: PlaidLinkOnSuccessMetadata
      ) => {
        if (options.onSuccess) {
          options.onSuccess(public_token, metadata);
        }
      },
      onExit: (
        err: PlaidLinkError | null,
        metadata: PlaidLinkOnExitMetadata
      ) => {
        if (options.onExit) {
          options.onExit(err, metadata);
        }
      },
      onEvent: (eventName: string, metadata: PlaidLinkOnEventMetadata) => {
        if (options.onEvent) {
          options.onEvent(eventName, metadata);
        }
      },
    });

    setPlaidHandler(handler);

    return () => {
      handler.destroy();
    };
  }, [ready]);

  const open = useCallback(() => {
    if (plaidHandler) {
      plaidHandler.open();
    } else {
      console.log("Plaid handler is not initialized yet");
    }
  }, [plaidHandler]);

  const exit = useCallback(
    (force?: boolean) => {
      if (plaidHandler) {
        plaidHandler.exit(force);
      }
    },
    [plaidHandler]
  );

  return { error, ready, open, exit };
};

export type {
  PlaidAccount,
  PlaidInstitution,
  PlaidLinkError,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkOnExit,
  PlaidLinkOnEvent,
  PlaidLinkOnLoad,
  CommonPlaidLinkOptions,
  PlaidLinkOptionsWithPublicKey,
  PlaidLinkOptionsWithLinkToken,
  PlaidLinkOptions,
  PlaidHandler,
  PlaidEmbeddedHandler,
  Plaid,
};

export { PlaidLinkStableEvent };
