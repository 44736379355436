import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from '../admin/navigation/AdminSidebar';
import AdminHeader from '../admin/navigation/AdminHeader';

export default function AdminLayout() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <AdminSidebar />
      <div className="lg:pl-72">
        <AdminHeader />
        <main className="py-8 px-4 sm:px-6 lg:px-8">
          <Outlet />
        </main>
      </div>
    </div>
  );
}