import React from 'react';
import { Bell, UserPlus, CheckCircle, AlertTriangle } from 'lucide-react';

export default function NotificationsList() {
  const notifications = [
    {
      id: '1',
      type: 'access_request',
      title: 'New Access Request',
      message: 'Sarah Johnson requested access to investment tools',
      timestamp: '2024-03-19T14:30:00Z',
      status: 'pending',
    },
    {
      id: '2',
      type: 'task_completed',
      title: 'Task Completed',
      message: 'Financial review for Emily Parker completed',
      timestamp: '2024-03-19T12:15:00Z',
      status: 'completed',
    },
    // Add more notifications
  ];

  const getIcon = (type: string) => {
    switch (type) {
      case 'access_request':
        return <UserPlus className="h-5 w-5 text-blue-500" />;
      case 'task_completed':
        return <CheckCircle className="h-5 w-5 text-green-500" />;
      default:
        return <Bell className="h-5 w-5 text-gray-400" />;
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
      <div className="p-6">
        <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
          Recent Notifications
        </h2>

        <div className="space-y-4">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className="flex items-start space-x-4 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
            >
              <div className="flex-shrink-0">
                {getIcon(notification.type)}
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900 dark:text-white">
                  {notification.title}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {notification.message}
                </p>
                <p className="text-xs text-gray-400 dark:text-gray-500 mt-1">
                  {new Date(notification.timestamp).toLocaleString()}
                </p>
              </div>
              {notification.status === 'pending' && (
                <button className="px-3 py-1 text-xs font-medium text-primary-400 bg-primary-50 dark:bg-primary-900/20 rounded-full">
                  Review
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}