import React from "react";
import {
  TrendingUp,
  TrendingDown,
  AlertTriangle,
  CheckCircle,
  Target,
  DollarSign,
  Calendar,
} from "lucide-react";
import { Transaction } from "../../types/transaction";
import {
  format,
  isWithinInterval,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  startOfMonth,
} from "date-fns";
import { motion } from "framer-motion";
import { transactions } from "../../../server/transactions";
import { useTransactionStore } from "../../store/transactionStore";

interface TransactionInsightsProps {
  transactions: Transaction[];
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
}

function TransactionInsights({ dateRange }: TransactionInsightsProps) {
  const { transactions } = useTransactionStore();
  const calculateInsights = () => {
    const currentTransactions = transactions.filter((t) =>
      isWithinInterval(new Date(t.date), {
        start:
          dateRange.startDate instanceof Date
            ? dateRange.startDate
            : new Date(dateRange.startDate),
        end:
          dateRange.endDate instanceof Date
            ? dateRange.endDate
            : new Date(dateRange.endDate),
      })
    );

    // Calculate total income and expenses
    const expenses = currentTransactions.filter((t) => t.amount < 0);
    const income = currentTransactions.filter((t) => t.amount >= 0);
    const totalExpenses = expenses.reduce((sum, t) => sum + t.amount, 0);
    const totalIncome = income.reduce((sum, t) => sum + t.amount, 0);

    // Category analysis
    const categorySpending = expenses.reduce((acc, t) => {
      const category = t.category[0];
      acc[category] = (acc[category] || 0) + t.amount;
      return acc;
    }, {} as Record<string, number>);

    const sortedCategories = Object.entries(categorySpending).sort(
      ([, a], [, b]) => b - a
    );

    // Weekly trends
    const weekStart = startOfWeek(dateRange.startDate);
    const weekEnd = endOfWeek(dateRange.startDate);
    const weeklyExpenses = expenses.filter((t) =>
      isWithinInterval(new Date(t.date), { start: weekStart, end: weekEnd })
    );
    const weeklyTotal = weeklyExpenses.reduce((sum, t) => sum + t.amount, 0);

    // Monthly comparison
    const isCurrentMonth = isSameMonth(dateRange.startDate, new Date());
    const monthStart = startOfMonth(dateRange.startDate);
    const monthlyTotal = expenses
      .filter((t) =>
        isWithinInterval(new Date(t.date), {
          start: monthStart,
          end: dateRange.endDate,
        })
      )
      .reduce((sum, t) => sum + t.amount, 0);

    // Recurring transactions
    const recurringExpenses = currentTransactions.filter((t) => t.recurring);

    return {
      totalExpenses,
      totalIncome,
      netSavings: totalIncome - totalExpenses,
      savingsRate: ((totalIncome - totalExpenses) / totalIncome) * 100,
      topCategories: sortedCategories.slice(0, 3),
      weeklyAverage: weeklyTotal,
      monthlyTotal,
      isCurrentMonth,
      recurringExpenses: recurringExpenses.filter((t) => t.amount < 0),
      largestExpense: expenses.sort(
        (a, b) => Math.abs(b.amount) - Math.abs(a.amount)
      )[0],
    };
  };

  const insights = calculateInsights();

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-medium mb-6">Transactional Insights</h3>
      <div className="space-y-4">
        {/* Savings Rate */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className={`p-4 rounded-lg ${
            insights.savingsRate >= 20
              ? "bg-green-50 dark:bg-green-900/20"
              : insights.savingsRate >= 10
              ? "bg-yellow-50 dark:bg-yellow-900/20"
              : "bg-red-50 dark:bg-red-900/20"
          }`}
        >
          <div className="flex items-center">
            <DollarSign
              className={`h-5 w-5 mr-2 ${
                insights.savingsRate >= 20
                  ? "text-green-500"
                  : insights.savingsRate >= 10
                  ? "text-yellow-500"
                  : "text-red-500"
              }`}
            />
            <p className="font-medium">
              Savings Rate: {insights.savingsRate.toFixed(1)}%
            </p>
          </div>
          <p className="mt-1 text-sm">
            Net savings of ${insights.netSavings.toLocaleString()} this period
          </p>
        </motion.div>

        {/* Top Spending Categories */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="p-4 bg-primary-50 dark:bg-primary-900/20 rounded-lg"
        >
          <div className="flex items-center">
            <TrendingUp className="h-5 w-5 text-primary-400 mr-2" />
            <p className="font-medium">Top Spending Categories</p>
          </div>
          <div className="mt-2 space-y-2">
            {insights.topCategories.map(([category, amount], index) => (
              <div key={category} className="flex justify-between text-sm">
                <span>{category}</span>
                <span className="font-medium">
                  ${Math.abs(amount).toLocaleString()}
                </span>
              </div>
            ))}
          </div>
        </motion.div>

        {/* Largest Transaction */}
        {insights.largestExpense && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="p-4 bg-yellow-50 dark:bg-yellow-900/20 rounded-lg"
          >
            <div className="flex items-center">
              <AlertTriangle className="h-5 w-5 text-yellow-500 mr-2" />
              <p className="font-medium">Largest Expense</p>
            </div>
            <div className="mt-2 text-sm">
              <p>{insights.largestExpense.merchant_name}</p>
              <p className="font-medium">
                ${Math.abs(insights.largestExpense.amount).toLocaleString()}
              </p>
            </div>
          </motion.div>
        )}

        {/* Recurring Expenses */}
        {insights.recurringExpenses.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg"
          >
            <div className="flex items-center">
              <Calendar className="h-5 w-5 text-blue-500 mr-2" />
              <p className="font-medium">Recurring Expenses</p>
            </div>
            <div className="mt-2 space-y-2">
              {insights.recurringExpenses.slice(0, 3).map((expense) => (
                <div key={expense.id} className="flex justify-between text-sm">
                  <span>{expense.description}</span>
                  <span className="font-medium">
                    ${expense.amount.toLocaleString()}
                  </span>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default TransactionInsights;
