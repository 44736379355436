import React, { useState } from 'react';
import { Calendar, Filter } from 'lucide-react';
import ActivityList from '../../components/admin/activities/ActivityList';
import ActivityFilters from '../../components/admin/activities/ActivityFilters';
import ActivityMetrics from '../../components/admin/activities/ActivityMetrics';
import DateRangePicker from '../../components/admin/activities/DateRangePicker';

export default function ActivitiesTab() {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
  });
  const [filters, setFilters] = useState({
    type: 'all',
    client: 'all',
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Activities</h1>
        <DateRangePicker
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onChange={setDateRange}
        />
      </div>

      <ActivityMetrics dateRange={dateRange} />

      <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">Activity Log</h2>
            <ActivityFilters filters={filters} onChange={setFilters} />
          </div>
          
          <ActivityList dateRange={dateRange} filters={filters} />
        </div>
      </div>
    </div>
  );
}