import React, { useState } from "react";
import { format } from "date-fns";
import { X, MapPin, Receipt, Tag, Edit2, Save, Plus } from "lucide-react";
import { Transaction } from "../../types/transaction";
import { useTransactionStore } from "../../store/transactionStore";

interface TransactionDetailsModalProps {
  transaction: Transaction;
  onClose: () => void;
}

function TransactionDetailsModal({
  transaction,
  onClose,
}: TransactionDetailsModalProps) {
  const { updateNotes, addReceipt, addTags } = useTransactionStore();
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [notes, setNotes] = useState(transaction.notes || "");
  const [newTag, setNewTag] = useState("");

  const handleNotesSubmit = () => {
    updateNotes(transaction.transaction_id, notes);
    setIsEditingNotes(false);
  };

  const handleReceiptUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      await addReceipt(transaction.id, file);
    }
  };

  const handleAddTag = () => {
    if (newTag.trim()) {
      addTags(transaction.id, [newTag.trim()]);
      setNewTag("");
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={onClose}>
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" />

        <div
          className="relative w-full max-w-2xl bg-white dark:bg-gray-800 rounded-lg shadow-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
            <div>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                Transaction Details
              </h2>
              <p className="text-sm text-gray-500">
                {transaction?.authorized_date
                  ? format(new Date(transaction.authorized_date), "MMM d, yyyy")
                  : "N/A"}
                {/* {format(
                  new Date(`${transaction.date}T${transaction.time}`),
                  "h:mm a"
                )} */}
              </p>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="p-6 space-y-6">
            {/* Amount and Status */}
            <div className="flex justify-between items-center">
              <div>
                <p
                  className={`text-3xl font-bold ${
                    transaction.amount < 0 ? "text-red-500" : "text-green-500"
                  }`}
                >
                  {transaction.amount < 0
                    ? "-$" + (transaction.amount * -1).toLocaleString()
                    : "$" + transaction.amount.toLocaleString()}
                </p>
                {transaction.currency !== "USD" && (
                  <p className="text-sm text-gray-500">
                    {transaction.iso_currency_code}{" "}
                    {transaction.usdAmount?.toLocaleString()}
                  </p>
                )}
              </div>
              <span
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  !transaction.pending
                    ? "bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400"
                    : transaction.pending
                    ? "bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-400"
                    : "bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-400"
                }`}
              >
                {!transaction.pending ? "Completed" : "Pending"}
              </span>
            </div>

            {/* Merchant Details */}
            <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
              <div className="flex items-center space-x-4">
                {transaction.logo_url ? (
                  <img
                    src={transaction.logo_url}
                    alt={transaction.merchant_name || transaction.name}
                    className="h-12 w-12 rounded-full"
                  />
                ) : (
                  <div className="h-12 w-12 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
                    <span className="text-lg font-medium text-gray-600 dark:text-gray-300">
                      {transaction?.merchant_name?.charAt(0) ||
                        transaction?.name?.charAt(0)}
                    </span>
                  </div>
                )}
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    {transaction.merchant_name || transaction.name}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {transaction.category.join(", ")}
                  </p>
                </div>
              </div>
              {transaction.location && (
                <div className="mt-4 flex items-start">
                  <MapPin className="h-5 w-5 text-gray-400 mr-2 mt-0.5" />
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                      {transaction.location.address}
                    </p>
                    <p className="text-sm text-gray-500">
                      {transaction.location.city}, {transaction.location.state}
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/* Payment Details */}
            {/* <div>
              <h3 className="text-lg font-medium mb-4">Payment Details</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Method</p>
                  <p className="font-medium">
                    {transaction.paymentMethod
                      .replace("_", " ")
                      .replace(/\b\w/g, (l) => l.toUpperCase())}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Account</p>
                  <p className="font-medium">
                    {transaction.paymentDetails.accountName}
                    {transaction.paymentDetails.cardLast4 &&
                      ` (****${transaction.paymentDetails.cardLast4})`}
                  </p>
                </div>
              </div>
            </div> */}

            {/* Receipt */}
            <div>
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium">Receipt</h3>
                <label className="cursor-pointer">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleReceiptUpload}
                    className="hidden"
                  />
                  <div className="flex items-center text-primary-400 hover:text-primary-500">
                    <Receipt className="h-5 w-5 mr-2" />
                    <span>
                      {transaction.receipt
                        ? "Update Receipt"
                        : "Upload Receipt"}
                    </span>
                  </div>
                </label>
              </div>
              {transaction.receipt && (
                <div className="mt-2">
                  <img
                    src={transaction.receipt.url}
                    alt="Receipt"
                    className="max-h-48 rounded-lg"
                  />
                  <p className="text-xs text-gray-500 mt-1">
                    Uploaded on{" "}
                    {format(
                      new Date(transaction.receipt.uploadDate),
                      "MMM d, yyyy"
                    )}
                  </p>
                </div>
              )}
            </div>

            {/* Notes */}
            <div>
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium">Notes</h3>
                <button
                  onClick={() => setIsEditingNotes(!isEditingNotes)}
                  className="text-primary-400 hover:text-primary-500"
                >
                  {isEditingNotes ? (
                    <Save className="h-5 w-5" />
                  ) : (
                    <Edit2 className="h-5 w-5" />
                  )}
                </button>
              </div>
              {isEditingNotes ? (
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  onBlur={handleNotesSubmit}
                  className="w-full rounded-lg border border-gray-300 dark:border-gray-600 p-2 focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700"
                  rows={3}
                  placeholder="Add notes about this transaction..."
                />
              ) : (
                <p className="text-gray-600 dark:text-gray-300">
                  {notes || "No notes added"}
                </p>
              )}
            </div>

            {/* Tags */}
            <div>
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium">Tags</h3>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    className="border border-gray-300 dark:border-gray-600 rounded-lg px-3 py-1 text-sm focus:ring-primary-400 focus:border-primary-400 dark:bg-gray-700"
                    placeholder="Add tag"
                  />
                  <button
                    onClick={handleAddTag}
                    className="p-1 text-primary-400 hover:text-primary-500"
                  >
                    <Plus className="h-5 w-5" />
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap gap-2">
                <span
                  key={transaction.category}
                  className="px-3 py-1 bg-gray-100 dark:bg-gray-700 rounded-full text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  {transaction.category.join(", ")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionDetailsModal;
