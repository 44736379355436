import React, { memo } from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { ClientJourney } from "../../types/client";
import SortableClientCard from "./SortableClientCard";

interface PhaseColumnProps {
  phase: string;
  journeys: ClientJourney[];
  onJourneyClick: (journey: ClientJourney) => void;
}

const PhaseColumn = memo(
  ({ phase, journeys, onJourneyClick }: PhaseColumnProps) => {
    const { setNodeRef, isOver } = useDroppable({
      id: phase,
    });

    return (
      <div
        ref={setNodeRef}
        className={`bg-gray-50 dark:bg-gray-800 rounded-lg p-4 min-h-[500px] transition-colors duration-200 ${
          isOver ? "bg-gray-100 dark:bg-gray-700" : ""
        }`}
      >
        <h3 className="text-lg font-medium mb-4 text-center">
          Session {phase}
        </h3>
        <SortableContext
          items={journeys.map((j) => j.id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="space-y-4">
            {journeys.map((journey) => (
              <SortableClientCard
                key={journey.id}
                journey={journey}
                onClick={() => onJourneyClick(journey)}
              />
            ))}
          </div>
        </SortableContext>
      </div>
    );
  }
);

PhaseColumn.displayName = "PhaseColumn";

export default PhaseColumn;
