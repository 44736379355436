import { z } from 'zod';
import { TwoFactorSetup } from './auth';

export interface User {
  id: string;
  email: string;
  name: string;
  createdAt: string;
  password?: string;
  avatar?: string;
  phone?: string;
  location?: string;
  bio?: string;
  role: 'user' | 'admin';
}

export const profileSchema = z.object({
  name: z.string().min(2, 'Name must be at least 2 characters'),
  email: z.string().email('Invalid email address'),
  phone: z.string().optional(),
  location: z.string().optional(),
  bio: z.string().optional(),
});

export type ProfileFormData = z.infer<typeof profileSchema>;

export interface AuthState {
  user: Omit<User, 'password'> | null;
  isAuthenticated: boolean;
  error: string | null;
  twoFactorSetup: TwoFactorSetup | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, password: string, name: string) => Promise<void>;
  clearError: () => void;
  updateProfile: (data: ProfileFormData) => Promise<void>;
  updateAvatar: (file: File) => Promise<void>;
  setupTwoFactor: () => Promise<void>;
  verifyTwoFactor: (code: string) => Promise<void>;
  disableTwoFactor: () => Promise<void>;
}