import React from 'react';
import { Shield, Bell, Globe } from 'lucide-react';
import NotificationSettings from '../../components/settings/NotificationSettings';
import SecuritySettings from '../../components/settings/SecuritySettings';
import PreferenceSettings from '../../components/settings/PreferenceSettings';

export default function AdminSettings() {
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Admin Settings</h1>
      
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
        <div className="p-6">
          <div className="space-y-8">
            <SecuritySettings />
            <NotificationSettings />
            <PreferenceSettings />
          </div>
        </div>
      </div>
    </div>
  );
}