import React, { useState, useMemo } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { useTransactionStore } from "../../store/transactionStore";
import { motion, AnimatePresence } from "framer-motion";
import { isWithinInterval } from "date-fns";

const COLORS = [
  "#B100FF",
  "#10B981",
  "#F59E0B",
  "#6366F1",
  "#EC4899",
  "#8B5CF6",
];

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const data = payload[0];
    return (
      <div className="bg-white dark:bg-gray-800 p-3 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700">
        <p className="font-medium text-gray-900 dark:text-white">{data.name}</p>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          ${data.value.toLocaleString()}
        </p>
        <p className="text-xs text-gray-400">
          {((data.value / data.payload.total) * 100).toFixed(1)}% of total
        </p>
      </div>
    );
  }
  return null;
};

interface TransactionAnalyticsProps {
  grouping: "day" | "week" | "month";
  dateRange: { startDate: Date; endDate: Date };
}

function TransactionAnalytics({
  grouping,
  dateRange,
}: TransactionAnalyticsProps) {
  const { transactions } = useTransactionStore();
  const [hoveredCategory, setHoveredCategory] = useState<string | null>(null);
  const currentTransactions = transactions.filter((t) =>
    isWithinInterval(new Date(t.date), {
      start:
        dateRange.startDate instanceof Date
          ? dateRange.startDate
          : new Date(dateRange.startDate),
      end:
        dateRange.endDate instanceof Date
          ? dateRange.endDate
          : new Date(dateRange.endDate),
    })
  );

  const data = useMemo(() => {
    // Filter only expense transactions
    const expenses = currentTransactions.filter((t) => t.amount < 0);

    console.log(expenses);
    

    // Group by category and sum amounts
    const categoryData = expenses.reduce((acc, transaction) => {
      const category = transaction.category;
      acc[category] = (acc[category] || 0) + transaction.amount;
      return acc;
    }, {} as Record<string, number>);

    const total = Object.values(categoryData).reduce((a, b) => a + b, 0);

    return Object.entries(categoryData)
      .map(([name, value]) => ({
        name,
        value,
        total,
      }))
      .sort((a, b) => b.value - a.value);
  }, [currentTransactions]);

  const totalSpent = data.reduce((sum, item) => sum + item.value, 0);

  if (!data.length) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 text-center">
        <h3 className="text-lg font-medium mb-4">Spending by Category</h3>
        <p className="text-gray-500 dark:text-gray-400">
          No transaction data available
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-medium mb-6">Spending by Category</h3>

      <div className="relative h-80">
        {/* Center content */}
        <div className="absolute inset-0 flex items-center justify-center z-10 pointer-events-none">
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            className="text-center"
          >
            <p className="text-2xl font-bold text-gray-900 dark:text-white">
              ${Math.abs(totalSpent).toLocaleString()}
            </p>
            <p className="text-sm text-gray-500">Total Spent</p>
          </motion.div>
        </div>

        {/* Pie chart */}
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              paddingAngle={5}
              dataKey="value"
              startAngle={90}
              endAngle={450}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  opacity={
                    hoveredCategory && hoveredCategory !== entry.name ? 0.5 : 1
                  }
                  className="transition-opacity duration-200"
                />
              ))}
            </Pie>
            <Tooltip
              content={<CustomTooltip />}
              position={{ x: 0, y: 0 }}
              wrapperStyle={{ zIndex: 100 }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>

      {/* Category list */}
      <div className="mt-6 space-y-2">
        <AnimatePresence>
          {data.map((category, index) => (
            <motion.div
              key={category.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2, delay: index * 0.05 }}
              onMouseEnter={() => setHoveredCategory(category.name)}
              onMouseLeave={() => setHoveredCategory(null)}
              className={`flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg cursor-pointer transform transition-all duration-200 hover:scale-[1.02] ${
                hoveredCategory === category.name
                  ? "ring-2 ring-primary-400 shadow-md"
                  : ""
              }`}
            >
              <div className="flex items-center space-x-3">
                <div
                  className={`w-3 h-3 rounded-full transition-transform duration-200 ${
                    hoveredCategory === category.name ? "scale-125" : ""
                  }`}
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                />
                <span className="text-sm font-medium text-gray-900 dark:text-white">
                  {category.name}
                </span>
              </div>
              <div className="text-right">
                <span className="text-sm font-medium text-gray-900 dark:text-white">
                  ${Math.abs(category.value).toLocaleString()}
                </span>
                <div className="text-xs text-gray-500">
                  {((category.value / totalSpent) * 100).toFixed(1)}%
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default TransactionAnalytics;
