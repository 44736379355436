export const transactions = [
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 18.33,
    authorized_date: "2024-11-02",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2024-11-03",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 072515 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "VP6ALbz6aGIP6bqXpvx6Tebw3AjbkWf9yj7qM",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: -41.22,
    authorized_date: "2024-11-15",
    authorized_datetime: null,
    category: ["Transfer", "Payroll"],
    category_id: "21009000",
    check_number: null,
    counterparties: [],
    date: "2024-11-15",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "INTRST PYMNT",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "INCOME_WAGES",
      primary: "INCOME",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_INCOME.png",
    transaction_code: null,
    transaction_id: "qrMVDL6MbpSojVvdPy6jUnqlWZNqzjCgPeb6j",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 89.4,
    authorized_date: "2024-11-15",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants"],
    category_id: "13005000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "LOW",
        entity_id: null,
        logo_url: null,
        name: "FUN",
        phone_number: null,
        type: "merchant",
        website: null,
      },
    ],
    date: "2024-11-16",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: "FUN",
    name: "SparkFun",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "ENTERTAINMENT_SPORTING_EVENTS_AMUSEMENT_PARKS_AND_MUSEUMS",
      primary: "ENTERTAINMENT",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_ENTERTAINMENT.png",
    transaction_code: null,
    transaction_id: "Db6aqPE61jHb7j4NvnB7iMBa3pQBeXI3RAy4W",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 87,
    authorized_date: "2024-11-17",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Fast Food"],
    category_id: "13005032",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
        logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
        name: "McDonald's",
        phone_number: null,
        type: "merchant",
        website: "mcdonalds.com",
      },
    ],
    date: "2024-11-17",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: "3322",
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
    merchant_entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
    merchant_name: "McDonald's",
    name: "McDonald's",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_FAST_FOOD",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "6Q5W6Rk5eNtARxa59GrRSPAojJvA7kt8KEw1Q",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "mcdonalds.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 4.33,
    authorized_date: "2024-11-17",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Coffee Shop"],
    category_id: "13005043",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
        logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
        name: "Starbucks",
        phone_number: null,
        type: "merchant",
        website: "starbucks.com",
      },
    ],
    date: "2024-11-17",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
    merchant_entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
    merchant_name: "Starbucks",
    name: "Starbucks",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_COFFEE",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "X76nGy36gEi7PrGBJknPUzNVdlkNgLubj7B1j",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "starbucks.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: -1500,
    authorized_date: "2024-11-18",
    authorized_datetime: null,
    category: ["Travel", "Airlines and Aviation Services"],
    category_id: "22001000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
        logo_url:
          "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
        name: "United Airlines",
        phone_number: null,
        type: "merchant",
        website: "united.com",
      },
    ],
    date: "2024-11-18",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
    merchant_entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
    merchant_name: "United Airlines",
    name: "United Airlines",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRAVEL_FLIGHTS",
      primary: "TRAVEL",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRAVEL.png",
    transaction_code: null,
    transaction_id: "Rd6gMWl6QXhdexoJ4LaeFDW8V1nWjbCak6dPo",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "united.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 5.4,
    authorized_date: "2024-11-19",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2024-11-20",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 063015 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "vdyVDE6yM9hKr9vjJwkrImDykrnDMdHqeL5v1",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: 25,
    authorized_date: "2024-11-19",
    authorized_datetime: null,
    category: ["Payment", "Credit Card"],
    category_id: "16001000",
    check_number: null,
    counterparties: [],
    date: "2024-11-20",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "CREDIT CARD 3333 PAYMENT *//",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT",
      primary: "LOAN_PAYMENTS",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_LOAN_PAYMENTS.png",
    transaction_code: null,
    transaction_id: "lvjVDeRj95UDRe8vby7RH8rkGjEr5BtpgK9a4",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 6.33,
    authorized_date: "2024-12-02",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2024-12-03",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 072515 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "91Dn7qoDdNfAGLPl5WwGS6wBog5wyqC4y7PqD",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: -4.22,
    authorized_date: "2024-12-15",
    authorized_datetime: null,
    category: ["Transfer", "Payroll"],
    category_id: "21009000",
    check_number: null,
    counterparties: [],
    date: "2024-12-15",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "INTRST PYMNT",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "INCOME_WAGES",
      primary: "INCOME",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_INCOME.png",
    transaction_code: null,
    transaction_id: "k9P5D7RPxjfML8vKkRpLTNbr6zybG7iLD4PJB",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 89.4,
    authorized_date: "2024-12-15",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants"],
    category_id: "13005000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "LOW",
        entity_id: null,
        logo_url: null,
        name: "FUN",
        phone_number: null,
        type: "merchant",
        website: null,
      },
    ],
    date: "2024-12-16",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: "FUN",
    name: "SparkFun",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "ENTERTAINMENT_SPORTING_EVENTS_AMUSEMENT_PARKS_AND_MUSEUMS",
      primary: "ENTERTAINMENT",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_ENTERTAINMENT.png",
    transaction_code: null,
    transaction_id: "yNylKG6yMzUBDdwvRgqDc1QBXAbQzoS4nJNKX",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 12,
    authorized_date: "2024-12-17",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Fast Food"],
    category_id: "13005032",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
        logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
        name: "McDonald's",
        phone_number: null,
        type: "merchant",
        website: "mcdonalds.com",
      },
    ],
    date: "2024-12-17",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: "3322",
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
    merchant_entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
    merchant_name: "McDonald's",
    name: "McDonald's",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_FAST_FOOD",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "bkrVXBRr6QTQrpVPkabrUpDmGANDZWimKRDo1",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "mcdonalds.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 4.33,
    authorized_date: "2024-12-17",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Coffee Shop"],
    category_id: "13005043",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
        logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
        name: "Starbucks",
        phone_number: null,
        type: "merchant",
        website: "starbucks.com",
      },
    ],
    date: "2024-12-17",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
    merchant_entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
    merchant_name: "Starbucks",
    name: "Starbucks",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_COFFEE",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "mR7VDQ67gZH648vzxBX4Se5wynM5D8fgpGq4z",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "starbucks.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: -500,
    authorized_date: "2024-12-18",
    authorized_datetime: null,
    category: ["Travel", "Airlines and Aviation Services"],
    category_id: "22001000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
        logo_url:
          "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
        name: "United Airlines",
        phone_number: null,
        type: "merchant",
        website: "united.com",
      },
    ],
    date: "2024-12-18",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
    merchant_entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
    merchant_name: "United Airlines",
    name: "United Airlines",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRAVEL_FLIGHTS",
      primary: "TRAVEL",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRAVEL.png",
    transaction_code: null,
    transaction_id: "nleVDZ6eM1s8QZvk9xAQfwBzWNKBj9TAVax3n",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "united.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 5.4,
    authorized_date: "2024-12-19",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2024-12-20",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 063015 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "G9APzeVAyBfM8qd71NV8TdVnWj5VaXC6xVEGk",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: 25,
    authorized_date: "2024-12-19",
    authorized_datetime: null,
    category: ["Payment", "Credit Card"],
    category_id: "16001000",
    check_number: null,
    counterparties: [],
    date: "2024-12-20",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "CREDIT CARD 3333 PAYMENT *//",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT",
      primary: "LOAN_PAYMENTS",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_LOAN_PAYMENTS.png",
    transaction_code: null,
    transaction_id: "Jl6exMy64Rsl1zyZNWB1SPB7wNMBjktBMl7Qr",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 6.33,
    authorized_date: "2025-01-01",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2025-01-02",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 072515 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "gndVX4DdPZTNdZV9MabdTpEzW5666EtExQdoo",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: -4.22,
    authorized_date: "2025-01-14",
    authorized_datetime: null,
    category: ["Transfer", "Payroll"],
    category_id: "21009000",
    check_number: null,
    counterparties: [],
    date: "2025-01-14",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "INTRST PYMNT",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "INCOME_WAGES",
      primary: "INCOME",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_INCOME.png",
    transaction_code: null,
    transaction_id: "Emb4KjLbwEfGL9aPoKVLhNVEkoeeeVf4qP3yR",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 89.4,
    authorized_date: "2025-01-14",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants"],
    category_id: "13005000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "LOW",
        entity_id: null,
        logo_url: null,
        name: "FUN",
        phone_number: null,
        type: "merchant",
        website: null,
      },
    ],
    date: "2025-01-15",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: "FUN",
    name: "SparkFun",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "GENERAL_MERCHANDISE_OTHER_GENERAL_MERCHANDISE",
      primary: "GENERAL_MERCHANDISE",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_GENERAL_MERCHANDISE.png",
    transaction_code: null,
    transaction_id: "oqQVDM6QJNSk4e83Po14TEJg3z111JTodnGja",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 12,
    authorized_date: "2025-01-16",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Fast Food"],
    category_id: "13005032",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
        logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
        name: "McDonald's",
        phone_number: null,
        type: "merchant",
        website: "mcdonalds.com",
      },
    ],
    date: "2025-01-16",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: "3322",
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
    merchant_entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
    merchant_name: "McDonald's",
    name: "McDonald's",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_FAST_FOOD",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "LK6ZaLA64yuKweG1ND9wI59l76PPP9tkXyd7J",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "mcdonalds.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 4.33,
    authorized_date: "2025-01-16",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Coffee Shop"],
    category_id: "13005043",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
        logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
        name: "Starbucks",
        phone_number: null,
        type: "merchant",
        website: "starbucks.com",
      },
    ],
    date: "2025-01-16",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
    merchant_entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
    merchant_name: "Starbucks",
    name: "Starbucks",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_COFFEE",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "pKZVD16ZPpuk4j8xMeD4TW9gGJmmm9CpZN8w1",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "starbucks.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: -500,
    authorized_date: "2025-01-17",
    authorized_datetime: null,
    category: ["Travel", "Airlines and Aviation Services"],
    category_id: "22001000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
        logo_url:
          "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
        name: "United Airlines",
        phone_number: null,
        type: "merchant",
        website: "united.com",
      },
    ],
    date: "2025-01-17",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
    merchant_entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
    merchant_name: "United Airlines",
    name: "United Airlines",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRAVEL_FLIGHTS",
      primary: "TRAVEL",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRAVEL.png",
    transaction_code: null,
    transaction_id: "1N5pVbA543Uov9zDMdkvUL1R4VBBB1fp8GEz8",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "united.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 5.4,
    authorized_date: "2025-01-18",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2025-01-19",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 063015 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "Mr6M4pn6jGSrZmwL46DZFrDQ3XNNNDuLJ8GKg",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: -1225,
    authorized_date: "2025-01-18",
    authorized_datetime: null,
    category: ["Payment", "Credit Card"],
    category_id: "16001000",
    check_number: null,
    counterparties: [],
    date: "2025-01-19",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "CREDIT CARD 3333 PAYMENT *//",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT",
      primary: "LOAN_PAYMENTS",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_LOAN_PAYMENTS.png",
    transaction_code: null,
    transaction_id: "8MAGmloADVfrMqNXkRnMFBlpMzWWWlfWbVenX",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 611.33,
    authorized_date: "2024-11-02",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2024-11-03",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 072515 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "VP6ALbz6aGIP6bqXpvx6Tebw3AjbkWf9yj7qM",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: -4.22,
    authorized_date: "2024-11-15",
    authorized_datetime: null,
    category: ["Transfer", "Payroll"],
    category_id: "21009000",
    check_number: null,
    counterparties: [],
    date: "2024-11-15",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "INTRST PYMNT",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "INCOME_WAGES",
      primary: "INCOME",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_INCOME.png",
    transaction_code: null,
    transaction_id: "qrMVDL6MbpSojVvdPy6jUnqlWZNqzjCgPeb6j",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 89.4,
    authorized_date: "2024-11-15",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants"],
    category_id: "13005000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "LOW",
        entity_id: null,
        logo_url: null,
        name: "FUN",
        phone_number: null,
        type: "merchant",
        website: null,
      },
    ],
    date: "2024-11-16",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: "FUN",
    name: "SparkFun",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "ENTERTAINMENT_SPORTING_EVENTS_AMUSEMENT_PARKS_AND_MUSEUMS",
      primary: "ENTERTAINMENT",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_ENTERTAINMENT.png",
    transaction_code: null,
    transaction_id: "Db6aqPE61jHb7j4NvnB7iMBa3pQBeXI3RAy4W",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 12,
    authorized_date: "2024-11-17",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Fast Food"],
    category_id: "13005032",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
        logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
        name: "McDonald's",
        phone_number: null,
        type: "merchant",
        website: "mcdonalds.com",
      },
    ],
    date: "2024-11-17",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: "3322",
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
    merchant_entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
    merchant_name: "McDonald's",
    name: "McDonald's",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_FAST_FOOD",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "6Q5W6Rk5eNtARxa59GrRSPAojJvA7kt8KEw1Q",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "mcdonalds.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 4.33,
    authorized_date: "2024-11-17",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Coffee Shop"],
    category_id: "13005043",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
        logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
        name: "Starbucks",
        phone_number: null,
        type: "merchant",
        website: "starbucks.com",
      },
    ],
    date: "2024-11-17",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
    merchant_entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
    merchant_name: "Starbucks",
    name: "Starbucks",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_COFFEE",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "X76nGy36gEi7PrGBJknPUzNVdlkNgLubj7B1j",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "starbucks.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: -500,
    authorized_date: "2024-11-18",
    authorized_datetime: null,
    category: ["Travel", "Airlines and Aviation Services"],
    category_id: "22001000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
        logo_url:
          "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
        name: "United Airlines",
        phone_number: null,
        type: "merchant",
        website: "united.com",
      },
    ],
    date: "2024-11-18",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
    merchant_entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
    merchant_name: "United Airlines",
    name: "United Airlines",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRAVEL_FLIGHTS",
      primary: "TRAVEL",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRAVEL.png",
    transaction_code: null,
    transaction_id: "Rd6gMWl6QXhdexoJ4LaeFDW8V1nWjbCak6dPo",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "united.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 5.4,
    authorized_date: "2024-11-19",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2024-11-20",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 063015 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "vdyVDE6yM9hKr9vjJwkrImDykrnDMdHqeL5v1",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: 25,
    authorized_date: "2024-11-19",
    authorized_datetime: null,
    category: ["Payment", "Credit Card"],
    category_id: "16001000",
    check_number: null,
    counterparties: [],
    date: "2024-11-20",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "CREDIT CARD 3333 PAYMENT *//",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT",
      primary: "LOAN_PAYMENTS",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_LOAN_PAYMENTS.png",
    transaction_code: null,
    transaction_id: "lvjVDeRj95UDRe8vby7RH8rkGjEr5BtpgK9a4",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 6.33,
    authorized_date: "2024-12-02",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2024-12-03",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 072515 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "91Dn7qoDdNfAGLPl5WwGS6wBog5wyqC4y7PqD",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: -4.22,
    authorized_date: "2024-12-15",
    authorized_datetime: null,
    category: ["Transfer", "Payroll"],
    category_id: "21009000",
    check_number: null,
    counterparties: [],
    date: "2024-12-15",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "INTRST PYMNT",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "INCOME_WAGES",
      primary: "INCOME",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_INCOME.png",
    transaction_code: null,
    transaction_id: "k9P5D7RPxjfML8vKkRpLTNbr6zybG7iLD4PJB",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 89.4,
    authorized_date: "2024-12-15",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants"],
    category_id: "13005000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "LOW",
        entity_id: null,
        logo_url: null,
        name: "FUN",
        phone_number: null,
        type: "merchant",
        website: null,
      },
    ],
    date: "2024-12-16",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: "FUN",
    name: "SparkFun",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "ENTERTAINMENT_SPORTING_EVENTS_AMUSEMENT_PARKS_AND_MUSEUMS",
      primary: "ENTERTAINMENT",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_ENTERTAINMENT.png",
    transaction_code: null,
    transaction_id: "yNylKG6yMzUBDdwvRgqDc1QBXAbQzoS4nJNKX",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 12,
    authorized_date: "2024-12-17",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Fast Food"],
    category_id: "13005032",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
        logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
        name: "McDonald's",
        phone_number: null,
        type: "merchant",
        website: "mcdonalds.com",
      },
    ],
    date: "2024-12-17",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: "3322",
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
    merchant_entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
    merchant_name: "McDonald's",
    name: "McDonald's",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_FAST_FOOD",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "bkrVXBRr6QTQrpVPkabrUpDmGANDZWimKRDo1",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "mcdonalds.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 4.33,
    authorized_date: "2024-12-17",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Coffee Shop"],
    category_id: "13005043",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
        logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
        name: "Starbucks",
        phone_number: null,
        type: "merchant",
        website: "starbucks.com",
      },
    ],
    date: "2024-12-17",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
    merchant_entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
    merchant_name: "Starbucks",
    name: "Starbucks",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_COFFEE",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "mR7VDQ67gZH648vzxBX4Se5wynM5D8fgpGq4z",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "starbucks.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: -500,
    authorized_date: "2024-12-18",
    authorized_datetime: null,
    category: ["Travel", "Airlines and Aviation Services"],
    category_id: "22001000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
        logo_url:
          "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
        name: "United Airlines",
        phone_number: null,
        type: "merchant",
        website: "united.com",
      },
    ],
    date: "2024-12-18",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
    merchant_entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
    merchant_name: "United Airlines",
    name: "United Airlines",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRAVEL_FLIGHTS",
      primary: "TRAVEL",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRAVEL.png",
    transaction_code: null,
    transaction_id: "nleVDZ6eM1s8QZvk9xAQfwBzWNKBj9TAVax3n",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "united.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 5.4,
    authorized_date: "2024-12-19",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2024-12-20",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 063015 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "G9APzeVAyBfM8qd71NV8TdVnWj5VaXC6xVEGk",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: 25,
    authorized_date: "2024-12-19",
    authorized_datetime: null,
    category: ["Payment", "Credit Card"],
    category_id: "16001000",
    check_number: null,
    counterparties: [],
    date: "2024-12-20",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "CREDIT CARD 3333 PAYMENT *//",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT",
      primary: "LOAN_PAYMENTS",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_LOAN_PAYMENTS.png",
    transaction_code: null,
    transaction_id: "Jl6exMy64Rsl1zyZNWB1SPB7wNMBjktBMl7Qr",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 6.33,
    authorized_date: "2025-01-01",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2025-01-02",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 072515 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "gndVX4DdPZTNdZV9MabdTpEzW5666EtExQdoo",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: -4.22,
    authorized_date: "2025-01-14",
    authorized_datetime: null,
    category: ["Transfer", "Payroll"],
    category_id: "21009000",
    check_number: null,
    counterparties: [],
    date: "2025-01-14",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "INTRST PYMNT",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "INCOME_WAGES",
      primary: "INCOME",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_INCOME.png",
    transaction_code: null,
    transaction_id: "Emb4KjLbwEfGL9aPoKVLhNVEkoeeeVf4qP3yR",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 89.4,
    authorized_date: "2025-01-14",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants"],
    category_id: "13005000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "LOW",
        entity_id: null,
        logo_url: null,
        name: "FUN",
        phone_number: null,
        type: "merchant",
        website: null,
      },
    ],
    date: "2025-01-15",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: "FUN",
    name: "SparkFun",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "GENERAL_MERCHANDISE_OTHER_GENERAL_MERCHANDISE",
      primary: "GENERAL_MERCHANDISE",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_GENERAL_MERCHANDISE.png",
    transaction_code: null,
    transaction_id: "oqQVDM6QJNSk4e83Po14TEJg3z111JTodnGja",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: null,
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 112,
    authorized_date: "2025-01-16",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Fast Food"],
    category_id: "13005032",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
        logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
        name: "McDonald's",
        phone_number: null,
        type: "merchant",
        website: "mcdonalds.com",
      },
    ],
    date: "2025-01-16",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: "3322",
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/mcdonalds_619.png",
    merchant_entity_id: "vzWXDWBjB06j5BJoD3Jo84OJZg7JJzmqOZA22",
    merchant_name: "McDonald's",
    name: "McDonald's",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_FAST_FOOD",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "LK6ZaLA64yuKweG1ND9wI59l76PPP9tkXyd7J",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "mcdonalds.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 41.33,
    authorized_date: "2025-01-16",
    authorized_datetime: null,
    category: ["Food and Drink", "Restaurants", "Coffee Shop"],
    category_id: "13005043",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
        logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
        name: "Starbucks",
        phone_number: null,
        type: "merchant",
        website: "starbucks.com",
      },
    ],
    date: "2025-01-16",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/starbucks_956.png",
    merchant_entity_id: "NZAJQ5wYdo1W1p39X5q5gpb15OMe39pj4pJBb",
    merchant_name: "Starbucks",
    name: "Starbucks",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "FOOD_AND_DRINK_COFFEE",
      primary: "FOOD_AND_DRINK",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_FOOD_AND_DRINK.png",
    transaction_code: null,
    transaction_id: "pKZVD16ZPpuk4j8xMeD4TW9gGJmmm9CpZN8w1",
    transaction_type: "place",
    unofficial_currency_code: null,
    website: "starbucks.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: -2500,
    authorized_date: "2025-01-17",
    authorized_datetime: null,
    category: ["Travel", "Airlines and Aviation Services"],
    category_id: "22001000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
        logo_url:
          "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
        name: "United Airlines",
        phone_number: null,
        type: "merchant",
        website: "united.com",
      },
    ],
    date: "2025-01-17",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/united_airlines_1065.png",
    merchant_entity_id: "NKDjqyAdQQzpyeD8qpLnX0D6yvLe2KYKYYzQ4",
    merchant_name: "United Airlines",
    name: "United Airlines",
    payment_channel: "in store",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRAVEL_FLIGHTS",
      primary: "TRAVEL",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRAVEL.png",
    transaction_code: null,
    transaction_id: "1N5pVbA543Uov9zDMdkvUL1R4VBBB1fp8GEz8",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "united.com",
  },
  {
    account_id: "pKZVD16ZPpuk4j8xMeD4TW9vNyERnpCpo5JPe",
    account_owner: null,
    amount: 15.4,
    authorized_date: "2025-01-18",
    authorized_datetime: null,
    category: ["Travel", "Taxi"],
    category_id: "22016000",
    check_number: null,
    counterparties: [
      {
        confidence_level: "VERY_HIGH",
        entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
        logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
        name: "Uber",
        phone_number: null,
        type: "merchant",
        website: "uber.com",
      },
    ],
    date: "2025-01-19",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: "https://plaid-merchant-logos.plaid.com/uber_1060.png",
    merchant_entity_id: "eyg8o776k0QmNgVpAmaQj4WgzW9Qzo6O51gdd",
    merchant_name: "Uber",
    name: "Uber 063015 SF**POOL**",
    payment_channel: "online",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "VERY_HIGH",
      detailed: "TRANSPORTATION_TAXIS_AND_RIDE_SHARES",
      primary: "TRANSPORTATION",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_TRANSPORTATION.png",
    transaction_code: null,
    transaction_id: "Mr6M4pn6jGSrZmwL46DZFrDQ3XNNNDuLJ8GKg",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: "uber.com",
  },
  {
    account_id: "oqQVDM6QJNSk4e83Po14TEJvnjkmyXIoqNp6G",
    account_owner: null,
    amount: 125,
    authorized_date: "2025-01-18",
    authorized_datetime: null,
    category: ["Payment", "Credit Card"],
    category_id: "16001000",
    check_number: null,
    counterparties: [],
    date: "2025-01-19",
    datetime: null,
    iso_currency_code: "USD",
    location: {
      address: null,
      city: null,
      country: null,
      lat: null,
      lon: null,
      postal_code: null,
      region: null,
      store_number: null,
    },
    logo_url: null,
    merchant_entity_id: null,
    merchant_name: null,
    name: "CREDIT CARD 3333 PAYMENT *//",
    payment_channel: "other",
    payment_meta: {
      by_order_of: null,
      payee: null,
      payer: null,
      payment_method: null,
      payment_processor: null,
      ppd_id: null,
      reason: null,
      reference_number: null,
    },
    pending: false,
    pending_transaction_id: null,
    personal_finance_category: {
      confidence_level: "LOW",
      detailed: "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT",
      primary: "LOAN_PAYMENTS",
    },
    personal_finance_category_icon_url:
      "https://plaid-category-icons.plaid.com/PFC_LOAN_PAYMENTS.png",
    transaction_code: null,
    transaction_id: "8MAGmloADVfrMqNXkRnMFBlpMzWWWlfWbVenX",
    transaction_type: "special",
    unofficial_currency_code: null,
    website: null,
  },
];
