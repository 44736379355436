import React from 'react';
import { Search } from 'lucide-react';
import { useAuthStore } from '../../../store/authStore';
import NotificationsDropdown from '../notifications/NotificationsDropdown';
import UserMenu from './UserMenu';
import { useAdminNotifications } from '../../../hooks/useAdminNotifications';

export default function AdminHeader() {
  const { user } = useAuthStore();
  
  // Initialize notifications system
  useAdminNotifications();

  return (
    <header className="sticky top-0 z-30 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex-1 flex items-center">
            <div className="max-w-lg w-full lg:max-w-xs relative">
              <input
                type="search"
                placeholder="Search clients, tasks, or activities..."
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg leading-5 bg-white dark:bg-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:border-primary-400"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <NotificationsDropdown />
            <UserMenu user={user} />
          </div>
        </div>
      </div>
    </header>
  );
}